<script setup>
/*
    Manager home page
 */
import {onMounted} from 'vue'
import {Navbar, Sidebar} from '@/paks/vu-nav'
import {State} from '@/paks/vu-app'

import {onBeforeMount, reactive} from 'vue'

const page = reactive({
    nav: null,
})

onBeforeMount(async () => {
    page.nav = State.config.features.nav || {}
})

//  https://gist.github.com/nateps/1172490
onMounted(() => {
    let page = document.getElementById('manager-app')
    let ua = navigator.userAgent
    let iphone = ~ua.indexOf('iPhone') || ~ua.indexOf('iPod')
    let ipad = ~ua.indexOf('iPad')
    let ios = iphone || ipad
    // Detect if this is running as a fullscreen app from the homescreen
    let fullscreen = window.navigator.standalone
    // let android = ~ua.indexOf('Android')
    /*
    if (ios) {
        let height = document.documentElement.clientHeight
        if (iphone && !fullscreen) height += 60
        page.style.height = height + 'px'
    }
    setTimeout(scrollTo, 0, 0, 1)
    */
})
</script>

<template>
    <v-app id="manager-app">
        <Navbar v-if="page.nav.navbar" />
        <Sidebar v-if="page.nav.sidebar" />
        <v-main>
            <vu-tabs />
            <div class="display-area">
                <router-view :key="$route.fullPath"></router-view>
            </div>
        </v-main>
    </v-app>
</template>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    height: 100%;
    width: 100%;
}
body {
    height: 100%;
    padding: 0;
    margin: 0;
}
.v-main {
    color: rgb(var(--v-theme-text));
    padding-top: 0 !important;
    min-height: 800px;
    overflow: hidden;
    .display-area {
        height: 100% !important;
        margin: 0;
        .dashboard-home {
            padding: 0 !important;
        }
    }
    .page {
        padding: 8px 36px 36px 36px;
        height: 100%;
        width: 100%;
        @media (max-width: 640px) {
            padding: 8px 22px 22px 22px;
        }
    }
    .action {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border));
        background: none !important;
        box-shadow: none !important;
    }
}
.loading {
    position: fixed;
    opacity: 0.5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-family: sans-serif;
    animation: pulse 1s infinite;
}
@keyframes pulse {
    0% {
        opacity: 0.2;
    }
    60% {
        opacity: 0.6;
    }
    100% {
        opacity: 0.2;
    }
}
.slide-out-enter-active,
.slide-out-leave-active {
    transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) !important;
}
.slide-out-enter,
.slide-out-leave-to {
    transform: translateX(100%);
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease-in !important;
}
.v-icon.closer {
    position: fixed;
    padding: 6px;
    right: 0;
    top: 0;
}
.blackout {
    z-index: 198 !important;
}
.dock.default {
    padding-top: 48px;
    z-index: 199 !important;
}
.v-alert__border {
    border-width: 2px;
}
.v-btn__loader {
    .v-progress-circular {
        height: 13px !important;
        width: 13px !important;
    }
    .v-progress-circular__underlay {
        color: rgb(var(--v-theme-accent));
    }
}
button.v-btn--outlined {
    margin-right: 10px !important;
    border: 1px solid rgb(var(--v-theme-border-lighten-1));
    background: none !important;
    box-shadow: none !important;
}
.tooltip {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    background: rgba(10, 10, 10, 0.55);
    color: white;
    opacity: 0.95;
    border: 1px solid rgba(200, 200, 200, 0.5);
    border-radius: 6px;
    margin-top: 8px;
    padding: 8px;
    z-index: 200;
}
//  Vue 3 fix - GraphWidget styles not being emitted
.graph-widget {
    height: 100%;
    .canvas {
        padding: 3cqh 3cqw 5cqh 3cqw;
    }
    .canvas.with-header {
        padding: 11cqh 3cqw 5cqh 3cqw;
    }
}
</style>

<script setup>
import {onBeforeMount, onMounted, reactive} from 'vue'
import {State, getModels} from '@/paks/vu-app'
import Json5 from 'json5'
import Expression from '@/paks/js-expression'

const props = defineProps({
    widget: Object,
})

const {Action} = getModels()

const page = reactive({
    actions: [],
    clouds: [],
    dashboards: State.cache.dashboards.map((d) => d.name).sort(),
    expressionErrors: [],
    conditionErrors: [],
    hasCloud: null,
})

const Types = [
    {title: 'None', value: null, label: 'None', hint: 'No action'},
    {title: 'Change Dashboard', value: 'dashboard', label: 'Dashboard', hint: 'Dashboard name'},
    {title: 'UI Page', value: 'link', label: 'URL Path', hint: '/url'},
    {title: 'Automation Action', value: 'action', label: 'Action Name', hint: 'Action name'},
]

onBeforeMount(() => {
    if (!props.widget.action) {
        props.widget.action = {}
    }
    let clouds = State.find('Cloud').filter((c) => c.type != 'host')
    page.clouds = clouds
    page.hasCloud = clouds.filter((c) => c.id != State.config.evalCloud).length > 0
    let widget = props.widget
    delete widget.actionType
    delete widget.actionTarget
    delete widget.actions

    widget.action.conditions = widget.action.conditions || [{expression: 'true', params: '{}'}]
})

onMounted(async () => {
    page.actions = await getActions()
})

function getTypes() {
    let types
    let type = props.widget.type
    if (type == 'form') {
        types = Types.filter((t) => t.value == 'action')
        props.widget.action.type = types[0].value
    } else if (type == 'button') {
        types = Types.slice(0)
    } else {
        types = Types.filter((t) => t.value != 'dashboard')
    }
    if (!page.hasCloud) {
        types = types.filter((t) => t.value != 'action')
    }
    if (State.config.builder) {
        types = types.filter(t => t.value != 'action')
    }
    return types
}

async function getActions() {
    let cloudId = props.widget.cloudId || State.cache.clouds[0]?.id
    if (cloudId && !(cloudId == 'builder' && State.config.builder)) {
        let actions = await Action.find({cloudId})
        return actions.map((t) => t.name)
    }
    return []
}

function getType() {
    return Types.find((t) => t.value == props.widget.action.type)
}

function changeType() {
    let widget = props.widget
    widget.action.target = null
    if (!widget.action.type) {
        widget.action = {}
    }
}

function addCondition(arg, cond) {
    let widget = props.widget
    let action = widget.action
    if (cond) {
        try {
            if (cond.params) {
                Json5.parse(cond.params)
                page.conditionErrors = []
            }
            try {
                let expression = new Expression({debug: false})
                expression.parse(cond.expression || true)
                page.expressionErrors = []
            } catch (err) {
                page.expressionErrors = ['Cannot parse expression']
                return
            }
        } catch (err) {
            page.conditionErrors = ['Cannot parse condition']
        }
    }
    if (arg == 'new-else') {
        action.conditions.push({params: '{ }'})
    } else if (arg == 'new-elseif') {
        action.conditions.push({expression: ' ', params: '{}'})
    }
}

function removeCondition(arg, index) {
    props.widget.action.conditions.splice(index, 1)
}
</script>

<template>
    <div class="widget-action">
        <p class="mb-5">Widget Actions are invoked when the widget is clicked.</p>
        <vu-input
            v-model="widget.action.type"
            label="Action Type"
            type="select"
            :items="getTypes()"
            @input="changeType" />

        <vu-input
            v-if="widget.action.type == 'dashboard'"
            v-model="widget.action.target"
            item-value="name"
            item-title="name"
            label="Dashboards"
            type="select"
            :items="page.dashboards" />

        <vu-input
            v-else-if="widget.action.type == 'action'"
            v-model="widget.action.target"
            type="combo"
            :label="getType()?.label"
            :items="page.actions"
            :placeholder="getType()?.hint" />

        <vu-input
            v-else-if="widget.action.type"
            v-model="widget.action.target"
            type="text"
            :label="getType()?.label"
            :placeholder="getType()?.hint" />

        <vu-input
            v-if="widget.action.type == 'action' && page.clouds.length > 1"
            v-model="props.widget.cloudId"
            item-value="id"
            item-title="name"
            label="Cloud"
            type="select"
            :items="page.clouds" />

        <div
            class="vrow"
            v-if="widget.action.type == 'action' && (widget.type != 'input' && widget.type != 'form')"
            v-for="(cond, i) in widget.action.conditions">

            <vu-input
                v-if="cond.expression != null"
                v-model="cond.expression"
                type="text"
                :errors="page.expressionErrors"
                :label="i == 0 ? 'if' : 'elseif'"
                :name="`${i}-if`"
                placeholder="Expression"
                cols="11"
                variant="outlined"
                @change="addCondition('if', cond)" />

            <v-icon
                v-if="i > 0 && cond.expression != null"
                icon="$close"
                variant="outlined"
                @click="removeCondition('if', i)">
            </v-icon>

            <vu-input
                v-model="cond.params"
                type="textarea"
                rows="2"
                :label="cond.expression == null ? 'else' : 'then'"
                :name="`${i}-then`"
                :errors="page.conditionErrors"
                cols="4"
                placeholder="{JSON5}"
                variant="outlined"
                @change="addCondition('then', cond)" />

            <v-icon
                v-if="cond.expression == null"
                icon="$close"
                variant="outlined"
                @click="removeCondition('else', i)">
            </v-icon>

            <div>
                <v-btn
                    v-if="cond.expression != null"
                    class="else-button"
                    size="small"
                    variant="outlined"
                    @click="addCondition('new-else')">
                    Else
                </v-btn>
                <v-btn
                    v-if="cond.expression != null"
                    class="else-button"
                    size="small"
                    variant="outlined"
                    @click="addCondition('new-elseif')">
                    Else If
                </v-btn>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.widget-action {
    .else-button {
        border: 1px solid rgb(var(--v-theme-border-lighten-1)) !important;
        margin-bottom: 6px;
        width: 80px;
        display: block;
    }
}
</style>

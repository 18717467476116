<script setup>
import {onBeforeMount, reactive, watch} from 'vue'
import {Routes, State, can, canUser, clone, getRoutePath, getRouter, titlecase} from '@/paks/vu-app'

const SidebarWidth = 160

const page = reactive({
    menu: [],
    mobile: State.app.mobile,
    ready: false,
    show: false,
    width: SidebarWidth,
})

watch(() => `${State.auth.ready}-${State.auth.role}-${State.app.needs.repaint}`, makeMenu, {
    immediate: true,
})
watch(
    () => State.app.context,
    () => makeMenu(),
    {deep: true}
)
watch(
    () => getRoutePath(),
    () => {
        State.sidebar.changePath()
        makeMenu()
    }
)
watch(
    () => {
        return State.app.needs.sidebar
    },
    () => {
        makeMenu()
    }
)
watch(
    () => State.cache.dashboard,
    () => {
        State.sidebar.changeDashboard()
    },
    {deep: true}
)

watch(
    () => {
        return State.sidebar.show
    },
    () => {
        makeMenu()
    }
)

onBeforeMount(() => {
    page.width = State.app.mobile ? window.innerWidth : SidebarWidth
})

function makeMenu() {
    if (!State.auth.ready) return
    page.show = State.sidebar.show
    if (page.show == null) {
        page.show = 'full'
        State.sidebar.show = 'full'
    }
    page.menu.splice(0, page.menu.length)
    let routes = getRouter().getRoutes()
    routes = [routes.at(-1), ...routes.slice(0, -1)]
    makeMenuItems(routes)
    page.ready = true
}

function makeMenuItems(routes) {
    for (let route of routes) {
        let view = route.meta?.view
        if (!view) continue
        if (State.app.mock && !canUser({role: 'admin'}, view.role)) {
            continue
        }
        if (!can(view.role)) {
            continue
        }
        if (view.enable != null && !State.app.testContext(view.enable, true, false)) {
            continue
        }
        if (view.menu) {
            if (route.path && route.path.indexOf(':') >= 0) {
                route.path = Routes.template(route.path, State.app.context)
            }
            page.menu.push({
                path: view.path,
                menu: view.menu,
                icon: view.icon,
                disabled: view.disabled,
            })
        }
    }
}

function isActive(path) {
    let rpath = getRoutePath()
    if (path == '/') {
        return rpath == '/' ? true : false
    }
    return rpath.indexOf(path) == 0
}

function clicked() {
    if ((getRoutePath() == '/' && State.cache.dashboard.full) || State.app.mobile) {
        State.sidebar.show = 'none'
    }
}
</script>

<template>
    <v-navigation-drawer
        v-if="page.show && page.show != 'none'"
        clipped
        permanent
        app
        class="sidebar"
        :rail="page.show == 'mini' ? true : false"
        :width="page.width">
        <v-list class="pt-4 mt-4">
            <v-list-item
                v-for="item in page.menu"
                ripple
                :active="isActive(item.path)"
                :class="page.show"
                :key="item.menu"
                :to="item.path"
                :disabled="item.disabled"
                :id="'nav-' + item.menu.toLowerCase()"
                :value="item"
                @click="clicked">
                <template v-slot:prepend>
                    <v-tooltip
                        v-if="page.show == 'mini'"
                        right
                        nudge-left="4"
                        :text="item.menu"
                        :open-delay="100"
                        :open-hover="true">
                        <template v-slot:activator="{props}">
                            <v-icon :icon="item.icon" v-bind="props" size="large" />
                        </template>
                    </v-tooltip>
                    <v-icon :icon="item.icon" v-else size="large" />
                </template>
                <v-list-item-title v-if="page.show == 'full'">
                    {{ titlecase(item.menu) }}
                </v-list-item-title>
            </v-list-item>
            <v-spacer />
        </v-list>
    </v-navigation-drawer>
</template>

<style lang="scss">
@use 'sass:color';

.sidebar {
    padding-top: 24px;
    background-color: rgb(var(--v-theme-form)) !important;
    z-index: 200 !important;
    .v-list-item {
        min-height: 60px !important;
        padding-right: 0 !important;
        border-bottom: 1px solid rgb(var(--v-theme-border-lighten-1));
        .v-list-item__action {
            margin-right: 20px !important;
        }
        .v-list-item__spacer {
            width: 16px !important;
        }
        a {
            min-height: 60px;
            .v-list-item__icon {
                font-size: 2em;
            }
        }
        &.mini {
            padding-right: 0 !important;
        }
        &:hover {
            background-color: color.adjust(gray, $lightness: 55%);
            z-index: 200;
        }
        &:hover.v-theme--dark {
            background-color: black;
        }
    }
    .v-list-item--active,
    .side-menu-active {
        // THEME
        border-right: 4px solid #03a9f4 !important;
        color: inherit !important;
        caret-color: inherit !important;
    }
}
</style>

<script setup>
import {onMounted, reactive, ref} from 'vue'

const props = defineProps({
    dashboard: Object,
    widget: Object,
    state: Object,
    buttons: Array,
})

const page = reactive({
    fields: null,
    loading: null,
    pageSize: props.pageSize || 25,
    select: null,
})

const Fields = [
    {name: 'dimension'},
    {name: 'value', title: 'Value', format: (v) => v.toLocaleString()},
]

const table = ref(null)

defineExpose({update})

onMounted(() => {
    update()
})

async function update() {
    if (props.widget?.value) {
        for (let item of props.widget.value) {
            item.value = +(item.value.toFixed(0))
        }
        if (table.value) {
            table.value.update({wait: true})
        }
    }
}

function buttonClick() {}
function clicked() {}
</script>

<template>
    <div>
        <vu-table
            class="metric-table-widget"
            options="filter,toolbar"
            ref="table"
            width="100%"
            :data="widget.value"
            :fields="page.fields || Fields"
            :nodata="page.loading ? 'Loading...' : 'No Items'"
            :name="widget.subtitle"
            :pageSize="page.pageSize"
            :pivot="widget.pivot"
            :select="page.select"
            :title="widget.subtitle"
            sort="value:desc"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn
                    v-for="(btn, index) in buttons"
                    class="mr-2"
                    :color="index == 0 ? 'accent' : ''"
                    :variant="index > 0 ? 'outlined' : 'elevated'"
                    :key="btn"
                    @click="buttonClick(btn)">
                    {{ btn }}
                </v-btn>
            </template>
        </vu-table>
    </div>
</template>

<style lang="scss">
.metric-table-widget {
    padding: 40px 20px 20px 20px;
    font-size: 1rem;
    .table-title {
        font-size: 1.4rem !important;
        padding-top: 5px;
    }
    .table-data {
        width: 100%;
    }
}
</style>

<script setup>
const props = defineProps({
    body: String,
    color: {type: String, default: 'accent'},
    name: {type: String},
    row: {type: Boolean},
    subtitle: String,
    title: String,
    type: String,
    width: Number,
})
</script>

<template>
    <v-card class="vu-card" elevation="1" :class="color ? `border-${color}` : ''">
        <v-card-title class="card-title">
            <div>
                {{ title }}
                <span class="actions"><slot name="actions"></slot></span>
            </div>
            <div class="card-subtitle">{{ subtitle }}</div>
        </v-card-title>
        <v-card-text class="card-body">
            <div :class="row ? 'vrow' : ''">
                <slot></slot>
                {{ body }}
            </div>
        </v-card-text>
    </v-card>
</template>

<style lang="scss">
.vu-card {
    margin-bottom: 20px;
    border: 1px solid rgb(var(--v-theme-border-lighten-1)) !important;
    .card-title {
        color: rgb(var(--v-theme-text));
        font-size: 1.8rem;
        font-weight: normal;
        background: rgb(var(--v-theme-background));
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgb(var(--v-theme-border-lighten-1));
        display: block;
    }
    .card-subtitle {
        margin-top: 4px;
        font-size: 1rem;
    }
    .card-body {
        color: rgb(var(--v-theme-text));
        font-weight: normal;
        font-size: 1rem;
        padding-top: 6px;
        padding-bottom: 6px;
        line-height: 1.4em;
    }
    &.border-error {
        border-top: solid 2px rgb(var(--v-theme-error)) !important;
    }
    &.border-accent {
        border-top: solid 2px rgb(var(--v-theme-accent)) !important;
    }
    &.border-primary {
        border-top: solid 2px rgb(var(--v-theme-primary)) !important;
    }
}
</style>

<script setup>
import {onBeforeMount, reactive, ref} from 'vue'
import {Feedback, State, clone, getModels, getRoutes} from '@/paks/vu-app'
import {getView, replaceView, provision} from './DashUtils.js'

const props = defineProps({
    view: Object,
})

const emit = defineEmits(['input'])

const page = reactive({
    components: [],
    display: null,
    existing: false,
    roles: [],
    saving: false,
    view: {widgets: []},
})

const {Dashboard, Manager} = getModels()

const ReservedComponents = [
    'VuManager',
    'VuSign',
]

//  Component refs
const self = ref(null)
const confirm = ref(null)

onBeforeMount(() => {
    if (props.view) {
        page.view = clone(props.view)
    }
    let view = page.view
    page.existing = view.path ? true : false
    if (view.properties) {
        view.properties = JSON.stringify(view.properties, null, 4)
    } else {
        view.properties = '{}'
    }
    if (!view.role) {
        view.role = 'inherited'
    }
    if (view.component == 'Page' && !view.board) {
        view.board = {
            css: [],
            type: 'page',
            widgets: [],
            widgetCss: [],
        }
    }
    page.display = State.app.display
    let components = Object.keys(getRoutes().getComponents()).sort()
    page.components = components.filter(c => !ReservedComponents.includes(c))
    page.roles = ['inherited', 'public', 'user', 'admin', 'owner']
})

async function preSave() {
    let view = page.view
    if (view.name == 'New View') {
        throw new Error('Must set page name')
    }
    if (!page.existing) {
        let found = getView(view.path, page.display)
        if (found.key) {
            throw new Error('View path already in use')
        }
    }
    return true
}

async function save() {
    let view = clone(page.view)
    if (view.role == 'inherited') {
        view.role = null
    }
    if (view.properties) {
        view.properties = JSON.parse(view.properties)
    }
    if (view.component == 'Page' && !view.board) {
        view.board = {
            css: [],
            type: 'page',
            widgets: [],
            widgetCss: [],
        }
    }
    await replaceView(view)
    await provision({ui: State.app.display, update: true})
    emit('input')
}

async function copy() {
    if (
        !(await confirm.value.ask(`Do you want to create a copy of the page "${page.view.name} 2"?`, {
            width: 600,
        }))
    ) {
        return
    }
    let view = (page.view = clone(page.view))
    delete view.id
    view.name = `${view.name}-2`
    view.path = `${view.path}-2`
    await save()
}

async function deleteView() {
    let view = page.view
    if (!view.name) {
        return
    }
    if (!(await confirm.value.ask(`Do you want to delete the page"?`))) {
        return
    }
    let found = getView(view)
    if (found.key) {
        found.views.splice(found.key, 1)
    }
    Feedback.info('View Deleted')
    emit('input')
}

</script>

<template>
    <vu-form
        class="view-edit"
        help="/doc/ui/view/view-edit.html"
        ref="self"
        :data="page"
        :pre-save="preSave"
        :save="save"
        :title="`${page.existing ? 'Modify' : 'Add'} Page`">

        <vu-input
            v-model="page.view.path"
            label="Page URL"
            name="path"
            placeholder="Enter full URL Path"
            type="text"
            class="mb-2" />

        <vu-input
            v-model="page.view.title"
            label="Page Title"
            name="title"
            placeholder="Enter title"
            type="text"
            class="mb-2"
        />

        <vu-input type="select" v-model="page.view.component" label="Component" :items="page.components" />

        <vu-input
            v-if="page.view.component"
            label="Component Properties"
            v-model="page.view.properties"
            cols="6"
            placeholder="JSON Component Properties"
            type="textarea"
        />

        <vu-input
            v-model="page.view.menu"
            label="Sidebar Menu"
            name="menu"
            placeholder="Menu Text"
            type="text"
            class="mb-2" />

        <vu-input
            v-if="page.view.menu"
            v-model="page.view.icon"
            label="Menu Icon"
            name="icon"
            placeholder="Enter icon name"
            type="text"
            class="mb-2" />

        <vu-input
            v-if="!page.view.component"
            label="Redirect"
            v-model="page.view.redirect"
            cols="6"
            type="select"
            :items="page.components" />

        <vu-input
            v-if="page.view.component"
            label="Role"
            v-model="page.view.role"
            cols="6"
            type="select"
            :items="page.roles" />


        <div class="vrow">
            <vu-input label="Tab Navigation" v-model="page.view.hasTabs" cols="4" type="switch" />
        </div>

        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <!--
            <v-btn size="small" color="teal" @click="copy">Copy</v-btn>
            -->
            <v-btn size="small" color="none" @click="emit('input', null)">Cancel</v-btn>
            <v-btn size="small" color="error" v-if="page.view.id" @click="deleteView">Delete</v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
.view-edit {
    .pick-view {
        margin: 16px 0 0px 0;
        width: 100%;
        .v-input__control {
            border-color: black;
            border-radius: 4px;
        }
    }
    .v-select-list {
        padding: 0;
        border: solid 1px rgb(var(--v-theme-border-lighten-1));
        border-radius: 0;
    }
    h3 {
        font-weight: normal;
        color: rgb(var(--v-theme-text));
    }
    .layouts {
        margin: 10px 0 20px 0;
    }
    .actions {
        margin: 20px 0 20px 0;
    }
    .live-data {
        //  When grid, helps alignment
        flex-grow: 30;
    }
}
</style>

<script setup>
import {onMounted, onUnmounted, reactive, ref} from 'vue'
import {Progress, State, can, delay, navigate} from '@/paks/vu-app'
import DeviceClaim from './DeviceClaim.vue'
import {Device, Generic} from '@/paks/mgr-models'

const Fields = [
    {name: 'edit', icon: '$edit', width: '5%', desktop: true},
    // {name: 'choose', title: 'Select', icon: '$click', width: '5%'},
    {name: 'id', title: 'Device ID'},
    {name: 'created', title: 'Made', desktop: true},
    {name: 'product'},
    {name: 'version', desktop: true},
]

const page = reactive({
    active: null,
    device: {},
    select: {},
    showClaim: false,
    showEdit: false,
})

//  Component references
const table = ref(null)
const confirm = ref(null)

onMounted(async () => {
    if (can('admin')) {
        page.select = {actions: {Claim: 0, Release: 2}, multi: true, property: 'select'}
    } else {
        page.select = {}
    }
    daemon()
})

onUnmounted(() => {
    page.active = false
})

async function getData(args) {
    return await Device.find({}, args)
}

async function daemon() {
    page.active = true
    while (page.active) {
        if (State.auth.ready) {
            await table.value.update()
        }
        await delay(30 * 1000)
    }
}

async function releaseDevices(items) {
    if (!(await confirm.value.ask(`Do you want to release the selected devices?`))) {
        return
    }
    Progress.start()
    for (let item of items) {
        await Device.release({id: item.id})
    }
    await table.value.update('repeat')
    Progress.stop('Device Released')
}

async function clicked({action, item, items, column}) {
    if (!can('admin')) return
    if (action == 'add') {
        claimDevice({})
    } else if (action == 'edit') {
        for (let item of items) {
            editDevice(item)
            break
        }
    } else if (action == 'delete') {
        for (let item of items) {
            await deleteDevice(item)
        }
    } else if (action == 'cell') {
        if (column.name == 'edit') {
            editDevice(item)
        } else {
            State.app.setContext('deviceId', item.id)
            navigate(`/dashboards/${State.cache.dashboard.name}`)
        }
    } else if (action == 'claim') {
        await claimDevice({})
    } else if (action == 'release') {
        await releaseDevices(items)
    }
}

async function claimDevice(device) {
    page.showClaim = device ? true : false
    page.device = device || {}
    if (!device) {
        if (table.value) {
            table.value.update()
        }
    }
}

async function editDevice(device) {
    page.device = device || {}
    if (device) {
        page.device = await Generic.get('Device', {id: device.id})
    } else {
        if (table.value) {
            table.value.update()
        }
    }
    page.showEdit = device ? true : false
}
</script>

<template>
    <div class="page device-list">
        <vu-table
            name="devices"
            options="dynamic,filter,toolbar"
            sort="name:asc"
            ref="table"
            width="100%"
            :data="getData"
            :fields="Fields"
            :pageSize="25"
            :select="page.select"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn
                    class="mr-2"
                    color="accent"
                    @click="claimDevice({})">
                    Claim Device
                </v-btn>
            </template>
        </vu-table>

        <vu-panel v-model="page.showClaim" @close="claimDevice" :widths="['400px']">
            <DeviceClaim :id="page.device.id" @input="claimDevice" ref="edit" />
        </vu-panel>

        <vu-panel v-model="page.showEdit" @close="editDevice()" :widths="['600px']">
            <GenericEdit :item="page.device" model="Device" title="Modify Device" @input="editDevice()" ref="edit" />
        </vu-panel>

        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.device-list {
    @media (max-width: 640px) {
        height: calc(100vh - 255px);
    }
    .product-select {
        display: flex;
        padding: 16px 20px 16px 20px;
        margin-bottom: 20px;
        border: 1px solid rgb(var(--v-theme-border)) !important;
    }
    .v-input--switch {
        .v-input__slot {
            width: auto;
        }
    }
    .action {
        margin-right: 10px !important;
        border: 1px solid rgb(var(--v-theme-border));
        background: none !important;
        box-shadow: none !important;
    }
}
</style>

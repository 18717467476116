<script setup>
import {onMounted, reactive} from 'vue'

const props = defineProps({
    widget: Object,
})

const page = reactive({
    leds: [],
})

defineExpose({update})

onMounted(() => {
    update()
})

function update(params = {}) {
    let value = props.widget.value
    page.leds = []
    if (value && Array.isArray(value)) {
        for (let v of value) {
            page.leds.push({value: v, class: v ? 'on' : 'off'})
        }
    }
}
</script>

<template>
    <div class="led-widget">
        <v-row justify="space-around" class="leds">
            <div v-for="led in page.leds" class="led" :class="`led ${led.class}`" />
        </v-row>
    </div>
</template>

<style lang="scss" scoped>
@use 'sass:color';

.led-widget {
    .leds {
        padding: 20px;
        padding-top: 25%;
        .led {
            height: 20px;
            width: 40px;
            border: 2px solid rgb(var(--v-theme-border-darken-1));
            &.on {
                background: color.adjust(rgb(22, 211, 22), $lightness: 35%);
            }
            &.off {
                background: rgb(var(--v-theme-error));
            }
        }
    }
}
</style>

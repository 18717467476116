/*
    Dashboard.js -
 */

import {Rest} from '@/paks/js-rest'
import {State} from '@/paks/vu-state'
import {deny} from '@/paks/js-polyfill'

//  Needed for device clouds
function repairCss(css) {
    if (css && typeof css == 'object' && !Array.isArray(css)) {
        let list = []
        for (let [name, value] of Object.entries(css)) {
            list.push({name, value})
        }
        css = list
    }
    return css || []
}

const Dashboard = new Rest(
    'Dashboard',
    {
        clean: (dashboard) => {
            /*
                These fixes are needed to cleanup Hub stored dashboards
             */
            dashboard.css = dashboard.css || []
            dashboard.range = dashboard.range || {}
            dashboard.widgetCss = repairCss(dashboard.widgetCss || [])
            dashboard.widgets = repairCss(dashboard.widgets || [])

            //  REPAIR
            if (dashboard.design == null) {
                dashboard.design = !dashboard.fixed
            }
            if (dashboard.layout == 'flow') {
                dashboard.layout = 'grid'
            }
            if (dashboard.layout == 'grid') {
                dashboard.snap = true
            }

            delete dashboard.remaining
            delete dashboard.select
            delete dashboard.accountId

            dashboard.widgets = dashboard.widgets.filter(w => w.id)

            for (let [index, widget] of Object.entries(dashboard.widgets)) {
                widget = deny(widget, [
                    '_top', '_left', '_height', '_width', 'dark', 'value', 'priorCss', 
                    'period', 'proposed', 'loading', 'moved', 'zoomed', 'select',
                ])
                dashboard.widgets[index] = widget
                widget.action = widget.action || {}
                widget.css = repairCss(widget.css || [])
                widget.dimensions = widget.dimensions || []
                widget.range = widget.range || {}

                //  REPAIR
                if (typeof widget.anchor == 'string') {
                    widget.anchor = {}
                }
                widget.anchor = widget.anchor || {}
                if (widget.type == 'text') {
                    widget.type = 'label'
                }
                widget.width = widget.width?.toString()
                widget.height = widget.height?.toString()
                widget.top = widget.top?.toString()
                widget.left = widget.left?.toString()

                if (widget.min != null) {
                    widget.min = parseInt(widget.min) || 0
                }
                if (widget.max != null) {
                    widget.max = parseInt(widget.max) || 0
                }

                if (widget.z != null && typeof widget.z == 'string') {
                    widget.z = +widget.z
                }
                if (!widget.namespace || widget.namespace == 'None') {
                    widget.namespace = widget.metric = widget.dimensions = widget.statistic = null
                }
                if (!widget.range?.override) {
                    widget.range = {}
                }
                if (widget.type == 'table') {
                    delete widget.field
                }
                delete widget.units
            }
            return dashboard
        },
        getCurrent: async () => {
            /*
                Handle first time setup and create a default dashboard if required
            */
            let dashboard
            let name = State.config.features?.dash?.name
            if (name == 'auto') {
                let name = window.innerWidth <= 640 ? 'Mobile' : 'Desktop'
                dashboard = State.get('Dashboard', name)
            } else if (name) {
                dashboard = State.get('Dashboard', name)

            } else if (State.cache.dashboard?.name) {
                dashboard = State.get('Dashboard', State.cache.dashboard.name)
            }
            if (!dashboard) {
                dashboard = State.get('Dashboard', 'Default')
            }
            if (!dashboard) {
                if (State.cache.dashboards.length > 0) {
                    dashboard = State.cache.dashboards[0]
                } else {
                    dashboard = await Dashboard.create({name: 'Default'})
                }
            }
            State.cache.dashboard = dashboard
            return dashboard
        },
        set: async (dashboard) => {
            if (!dashboard) {
                State.cache.dashboard = {}
                dashboard = await Dashboard.getCurrent()
                return
            }
            let dashboards = State.cache.dashboards
            let index = dashboards.findIndex((d) => d.id == dashboard?.id)
            if (index < 0) {
                index = dashboards.length
            }
            dashboards[index] = State.cache.dashboard = dashboard
        },
        setByName: async (name) => {
            let dashboard = State.cache.dashboards.find((d) => d.name == name)
            if (dashboard) {
                await Dashboard.set(dashboard)
            }
        },
    },
    {
        cache: true,
        service: () => State.config.builder ? '/api' : '',
        callback: async (reason, args) => {
            if (reason == 'before') {
                if (args.action == 'create' || args.action == 'update') {
                    args.fields = Dashboard.clean(args.fields)
                }
            } else if (reason == 'after') {
                args.fields = Dashboard.clean(args.fields)
            }
        }
    }
)

export default Dashboard

<script setup>
import {onUnmounted, onMounted, reactive, watch} from 'vue'
import {State} from '@/paks/vu-app'
import {clone, fixShadow, getRoute, getRoutes} from '@/paks/vu-app'
import Board from './Board.vue'

const props = defineProps({
    refresh: Number,
    view: Object,
})

const page = reactive({
    board: null,
    design: null,
    options: {expand: false},
    view: null,
})

defineExpose({page})

watch(
    () => State.app.needs.board,
    async (v) => {
        if (v == 'reload') {
            reload()
        }
    }
)

onMounted(async () => {
    reload()
})

function reload() {
    let path = getRoute().query?.path
    let view
    if (path) {
        view = getRoutes().getView(path)
        //  Design mode
        page.design = true
    } else {
        //  Live page mode
        view = getRoute().meta.view
        page.design = false
    }
    if (!view) return;
    page.view = clone(view)
    page.board = page.view.board
    if (path) {
        page.board.live = false
    }
    //  REPAIR
    page.board.design = false
    if (page.board?.widgets) {
        prepWidgets(page.board)
    }
    page.board.type = 'page'
}

onUnmounted(() => {
    fixShadow()
})

function prepWidgets(board) {
    let widgets = board.widgets
    let id = 1
    for (let widget of widgets) {
        if (State.cache.clouds.length == 1) {
            widget.cloudId = State.cache.clouds[0].id
        }
        //  Elevate data {}
        for (let [key, value] of Object.entries(widget.data || {})) {
            if (key == 'filter') {
                key = 'dimensions'
            }
            widget[key] = value
            widget.namespace = widget.namespace || 'Database'
        }
        for (let [key, value] of Object.entries(widget.metric || {})) {
            if (key == 'name') {
                key = 'metric'
            } else if (key == 'filter') {
                key = 'dimensions'
            }
            widget[key] = value
        }
        if (widget.to) {
            widget.action = {type: 'link', target: widget.to}
            delete widget.to
        }
        widget.css = widget.css || []
        widget.dimensions = widget.dimensions || {}
        widget.range = widget.range || {}
        widget.id = `${id++}`
        delete widget.data
    }
}
</script>

<template>
    <Board
        v-if="page.board"
        v-model="page.board"
        :options="page.options"
        :design="page.design"
        :type="'page'"
        :view="page.view" />
</template>

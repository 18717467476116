<script setup>
import {onMounted, reactive, ref} from 'vue'
import {Progress, State, navigate, getModels} from '@/paks/vu-app'
import {arrayBufferToBase64, arrayBufferToString} from '@/paks/js-base64'
import {provision} from '@/paks/vu-dash'
import Json5 from 'json5'

const MaxCss = 256 * 1024
const MaxDisplay = 256 * 1024
const MaxImage = 256 * 1024

const props = defineProps({})

const emit = defineEmits(['input'])

const page = reactive({
    css: null,
    display: null,
    files: {
        app: null,
        css: null,
        display: null,
        logo: null,
    },
    logo: null,
    manager: {},
    name: null,
    resetCss: null,
    resetDisplay: null,
    resetLogo: null,
    title: null,
})

const {Manager} = getModels()

//  Component refs
const self = ref(null)
const confirm = ref(null)

onMounted(async () => {
    page.manager = await Manager.get()
    //  REPAIR
    if (!page.manager.assets) {
        page.manager.assets = {}
    }
    let display = State.app.display
    page.name = display.name
    page.title = display.title
})

async function preSave() {
    let {files, manager} = page
    if (page.resetCss) {
        delete manager.assets.css
        files.css = null
    }
    if (page.resetLogo) {
        delete manager.assets.logo
        files.logo = null
    }
    if (page.resetDisplay) {
        delete manager.assets.display
        files.display = null
    }
    return true
}

async function save(validate) {
    let {files, manager} = page
    page.saving = true
    let params = {
        id: manager.id,
        name: manager?.name?.trim(),
        title: manager?.title?.trim() || null,
        assets: manager.assets,
    }
    Progress.start('dialog', 'Updating App', {bar: true, duration: 30 * 1000})
    page.manager = await Manager.update(params)

    params = {
        id: manager.id,
        wait: 3 * 60 * 1000,
        update: true,
    }
    if (files.css) {
        params.css = await getAsset(files.css, MaxCss)
    }
    if (files.logo) {
        params.logo = await getAsset(files.logo, MaxImage)
    }
    if (files.display) {
        params.display = await getAsset(files.display, MaxDisplay)
    } else {
        let display = State.app.display
        display.name = page.name
        display.title = page.title
        params.ui = display
    }
    await provision(params)
    Progress.stop()
    page.saving = false
    validate.clear()
}

/*
    Get an uploaded file and return an object with: {
    }
 */
async function getAsset(file, max, format = '') {
    if (!file) {
        throw new Error('Missing upload')
    }
    if (file.size > max) {
        throw new Error(`Asset ${file.name} is bigger than max ${max / 1024 / 1024} MB`)
    }
    let reader = new FileReader()
    reader.readAsArrayBuffer(file)

    let data = await new Promise((resolve, reject) => {
        reader.onload = async () => {
            let data = reader.result
            if (format == 'json') {
                try {
                    //  Validate as Json5 and convert to JSON
                    let string = String.fromCharCode.apply(null, new Uint8Array(data))
                    data = Json5.parse(string)
                    data = JSON.stringify(data, null, 4)
                } catch (err) {
                    reject(`${file.name} contains invalid JSON`)
                }
            } else {
                // data = arrayBufferToBase64(data)
                data = arrayBufferToString(data)
            }
            resolve(data)
        }
    })
    return {name: file.name, size: file.size, data}
}

function cancel() {
    navigate('/designer/pages')
}

async function exportDisplay() {
    let display = State.app.display
    let data = Json5.stringify(display, null, 4)
    let blob = new Blob([data], {type: 'text/plain'})
    let link = document.createElement('a')
    link.download = 'display.json5'
    if (window.webkitURL != null) {
        link.href = window.webkitURL.createObjectURL(blob)
    } else {
        link.href = window.URL.createObjectURL(blob)
        link.style.display = 'none'
        document.body.appendChild(link)
    }
    link.click()
    emit('input')
}
</script>

<template>
    <vu-form
        class="app-theme page-form ma-5"
        cols="6"
        help="/doc/ui/view/app-theme.html"
        ref="self"
        :data="page"
        :pre-save="preSave"
        :save="save">
        <vu-input
            v-model="page.title"
            cols="6"
            label="App Title"
            name="title"
            placeholder="Short app title"
            type="text"
            clearable />

        <vu-input v-model="page.name" label="Internal App Name" name="name" placeholder="Enter app name" type="text" cols="6" />

        <div class="vrow">
            <vu-input v-model="page.css" label="Upload CSS Stylesheet" type="checkbox" cols="3" hide-details />
            <vu-input
                v-if="!page.css && page.manager.assets?.css"
                v-model="page.resetCss"
                label="Reset CSS"
                type="checkbox"
                hide-details="auto"
                variation="compact"
                cols="6" />
        </div>
        <vu-input
            v-if="page.css"
            v-model="page.files.css"
            accept="text/css"
            class="file-input mb-4"
            density="compact"
            hide-details="auto"
            label="CSS Stylesheet"
            name="css"
            show-size
            type="file"
            :clearable="true"
            :multiple="false" />
        <div class="vrow">
            <vu-input v-model="page.logo" label="Upload Logo" type="checkbox" cols="6" hide-details />
            <vu-input
                v-if="!page.logo && page.manager.assets?.logo"
                v-model="page.resetLogo"
                label="Reset Logo"
                type="checkbox"
                hide-details="auto"
                variation="compact"
                cols="6" />
        </div>
        <vu-input
            v-if="page.logo"
            v-model="page.files.logo"
            accept="image/*"
            class="file-input mb-4"
            density="compact"
            hide-details="auto"
            label="App Logo"
            name="logo"
            show-size
            type="file"
            :clearable="true"
            :multiple="false" />
        <div class="vrow">
            <vu-input v-model="page.display" label="Upload Display" type="checkbox" cols="6" hide-details />
            <vu-input
                v-if="!page.display && page.manager.assets?.display"
                v-model="page.resetDisplay"
                class="mt-1"
                label="Reset Display"
                type="checkbox"
                hide-details="auto"
                variation="compact"
                cols="6" 
                />
        </div>
        <vu-input
            v-if="page.display"
            v-model="page.files.display"
            accept="*"
            cols="12"
            class="file-input mb-4"
            density="compact"
            hide-details="auto"
            name="display"
            show-size
            type="file"
            :clearable="true"
            _placeholder="Display.json5"
            :multiple="false" />
        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn size="small" color="teal" @click="exportDisplay">Export Display</v-btn>
            <v-btn size="small" color="none" @click="cancel">Cancel</v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
.app-theme {
    .v-select-list {
        padding: 0;
        border: solid 1px rgb(var(--v-theme-border-lighten-1));
        border-radius: 0;
    }
    h3 {
        font-weight: normal;
        color: rgb(var(--v-theme-text));
    }
    .layouts {
        margin: 10px 0 20px 0;
    }
    .actions {
        margin: 20px 0 20px 0;
    }
    .live-data {
        //  When grid, helps alignment
        flex-grow: 30;
    }
}
</style>
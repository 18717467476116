/*
    LocalStorage.js - Store persistent data in browser cache
 */

export class StorageClass {

    constructor(options = {}) {
        this.prefix = options.prefix || 'js-storage'
    }

    init(options) {
        this.prefix = options.prefix || 'js-storage'
    }

    getValue(key) {
        let data = localStorage[this.prefix + key]
        try {
            return data ? JSON.parse(data) : null
        } catch (err) {
            return null
        }
    }

    setValue(key, value) {
        return localStorage[this.prefix + key] = JSON.stringify(value)
    }

    removeValue(key) {
        delete localStorage[this.prefix + key]
    }

    setProperty(key, property, value) {
        let obj = this.getValue(key) || {}
        obj[property] = value
        this.setValue(key, obj)
    }

    setProperties(key, obj) {
        for (let [property,value] of Object.entries(obj)) {
            this.setProperty(key, property, value)
        }
    }

    print() {
        let total = 0
        let items = []
        for (let item in localStorage) {
            if (!localStorage.hasOwnProperty(item)) {
                continue
            }
            let size = localStorage[item].length
            items.push({name: item, size})
            total += size
        }
        items = items.sort((a,b) => {
            if (a.size < b.size) {
                return -1
            } else if (a.size > b.size) {
                return 1
            }
            return 0
        }).reverse()
        for (let item of items) {
            console.log(item.name.substr(0, 50) + " = " + (item.size / 1024).toFixed(2) + " KB")
        }
        console.log("Total = " + (total / 1024).toFixed(2) + " KB")
    }
}

const Storage = new StorageClass()

export {Storage}

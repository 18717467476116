<script setup>
import {reactive} from 'vue'
import {State, getModels} from '@/paks/vu-app'
import Json5 from 'json5'

const emit = defineEmits(['input'])

const page = reactive({
    dashboard: null,
    dname: State.config.builder ? 'Dashboard' : 'Page',
    files: [],
    name: null,
    saving: null,
})

const {Dashboard} = getModels()

async function preSave() {
    let dashboard = await importDash()
    if (!dashboard) {
        return false
    }
    dashboard.name = page.name || dashboard.name
    if (!dashboard.name) {
        throw new Error('Missing Dashboard Name')
    }
    if (await Dashboard.get({name: dashboard.name})) {
        throw new Error('Dashboard name already in use')
    }
    for (let widget of dashboard.widgets) {
        if (widget.cloudId && widget.cloudId != 'builder') {
            if (!State.cache.clouds.find(c => c.id == widget.cloudId)) {
                if (State.cache.clouds.length > 0) {
                    widget.cloudId = State.cache.clouds[0].id
                } else {
                    widget.cloudId = null
                }
            }
        }
    }
    page.dashboard = dashboard
    return true
}

async function save() {
    let dashboard = await Dashboard.create(page.dashboard)
    await Dashboard.set(dashboard)
    State.app.setNeed('board', 'reload')
    emit('input')
}

async function importDash() {
    let reader = new FileReader()
    let file = page.files[0]
    if (!file) return null

    reader.readAsArrayBuffer(file)
    return await new Promise((resolve, reject) => {
        reader.onload = async () => {
            try {
                let string = String.fromCharCode.apply(null, new Uint8Array(reader.result))
                let dashboard = Json5.parse(string)
                if (page.name) {
                    dashboard.name = page.name
                }
                resolve(dashboard)
            } catch (err) {
                reject(new Error(`${file.name} contains invalid JSON`))
            }
        }
    })
}
</script>

<template>
    <vu-form
        class="import-dash"
        help="/doc/ui/dashboard/dashboard-import.html"
        :data="page"
        :pre-save="preSave"
        :save="save"
        :title="`Import ${page.dname}`">

        <vu-input label="Select file to import" v-model="page.files" type="file" 
            clearable 
            show-size
            />

        <vu-input
            v-model="page.name"
            placeholder="Name"
            :label="`Override ${page.dname} Name`"
            type="text" />

        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Import</v-btn>
            <v-btn size="small" color="none" @click="emit('input')">Cancel</v-btn>
        </div>
    </vu-form>
</template>

<style lang="scss"></style>

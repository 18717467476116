<script setup>
import {Device, Generic} from '@/paks/mgr-models'
import {onBeforeMount, reactive, ref} from 'vue'
import {Feedback, Progress, State, can, clone, getModel, navigate, titlecase} from '@/paks/vu-app'

const TypeField = '_type'
let Forever = new Date(2100, 1, 1)

const props = defineProps({
    item: Object,
    model: String,
    panel: Object,
    title: String,
})
const emit = defineEmits(['input', 'update:modelValue'])

const page = reactive({
    canDelete: false,
    fields: [],
    hasCloud: State.config.features.cloud?.enable,
    item: null,
    releasing: false,
    removing: false,
    rules: [],
    roles: [],
    panel: null,
    saving: false,
    title: null,
    values: {},
})

const StoreFields = [
    {name: 'deviceId', type: 'string', disabled: true},
    {name: 'key', type: 'string'},
    {name: 'value', type: 'object'},
    {name: 'type', type: 'select', items: ['string', 'number', 'date', 'boolean']},
    {name: 'updated', type: 'date'},
    {name: 'expires', type: 'date', format: (v) => (!v || v.getTime() == 0) ? 'never' : v},
]
const CommmandFields = [
    {name: 'accountId', type: 'string', disabled: true},
    {name: 'deviceId', type: 'string', disabled: true},
    {name: 'description', type: 'string'},
    {name: 'command', type: 'string'},
    {name: 'args', type: 'object'},
    {name: 'expires', type: 'date'},
    {name: 'updated', type: 'date'},
    {name: 'expires', type: 'date', format: (v) => (!v || v.getTime() == 0) ? 'never' : v},
]
const confirm = ref(null)

onBeforeMount(async () => {
    page.item = clone(props.item)
    page.canDelete = true || can('admin')
    page.schema = State.app.schema
    page.fields = props?.panel?.fields?.filter((f) => can(f.role)) || []
    page.canRelease = props.model == 'Device' && page.hasCloud
    if (page.fields.length == 0) {
        if (props.model == 'Store') {
            page.fields = StoreFields
            page.item.expires = page.item.expires || Forever
        } else if (props.model == 'Command') {
            page.fields = CommandFields
            page.item.expires = page.item.expires || Forever
        } else {
            let model = getModel(props.model)
            if (model) {
                let fields = Object.keys(model).map((name) => {
                    return {name, type: model[name].type}
                })
                page.fields = fields.filter((f) => f.name != 'pk' && f.name != 'sk' && f.name != '_source')
            }
        }
    }
    for (let field of page.fields) {
        field.value = page.item[field.name]
        let sfield = page.schema.models[props.model]
        if (sfield && sfield[field.name] && (sfield[field.name].readonly || sfield[field.name].hidden)) {
            field.disabled = true
        } else if (field.name == 'created') {
            field.disabled = true
        } else if (props.model == 'Store' && field.name == 'key') {
            field.disabled = true
        }
    }
    let type = page.item[TypeField] || page.model
    page.title = props.title || `${type ? 'Modify' : 'Create'} ${props.model} Item`
})

async function save() {
    let item = page.item
    item.deviceId = State.app.context.deviceId
    if (item[TypeField]) {
        await Generic.update(item[TypeField], item)
    } else {
        await Generic.create(item[TypeField], item)
    }
    emit('input', item)
}

async function remove() {
    if (await confirm.value.ask(`Do you want to remove this item? `)) {
        try {
            let item = page.item
            page.removing = true
            await Generic.remove(item._type, item)
            Feedback.info('Item Removed')
            emit('input')
        } catch (err) {
            Feedback.error(err.message)
        } finally {
            page.removing = true
        }
    }
}

async function release() {
    if (await confirm.value.ask(`Do you want to release this device? `)) {
        try {
            let item = page.item
            page.releasing = true
            Progress.start('dialog', `Releasing ${item.id}`)
            await Device.remove({id: item.id})
            if (State.app.context.deviceId == item.id) {
                State.app.setContext('deviceId', null)
            }
            Feedback.info('Device Released')
            emit('input')
            navigate('/devices')
        } catch (err) {
            Feedback.error(err.message)
        } finally {
            Progress.stop()
            page.releasing = true
        }
    }
}

function getFieldType(field) {
    let type = field.type
    switch (type) {
        case 'date':
            return 'date'
        case 'number':
            return 'text'
        case 'string':
            return 'text'
        case 'select':
            return 'select'
        case 'object':
            return 'text'
        default:
            return field.type || 'text'
    }
}
</script>

<template>
    <vu-form :data="page" :title="page.title" :save="save" :options="{enter: false}">
        <vu-input
            v-for="field of page.fields.filter((f) => can(f.role))"
            v-model="page.item[field.name]"
            :cols="field.width"
            :disabled="field.disabled"
            :items="field.items"
            :key="field.name"
            :label="field.label || titlecase(field.name)"
            :name="field.name"
            :rules="page.rules[field.rules]"
            :type="getFieldType(field)" />

        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn size="small" color="none" @click="emit('input')">Cancel</v-btn>
            <v-btn
                v-if="page.item[TypeField] && page.canDelete && !page.canRelease"
                dark
                size="small"
                color="error"
                :loading="page.removing"
                @click="remove">
                Delete
            </v-btn>
            <v-btn v-if="page.canRelease" size="small" dark color="teal" :loading="page.releasing" @click="release">
                Release Device
            </v-btn>
        </div>
        <vu-confirm ref="confirm" />
    </vu-form>
</template>

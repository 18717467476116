/*
    Rest.js - REST API supports

    export default new Rest('user', {
        check: { method: 'POST', invoke: fn },
        login: { method: 'POST', uri: '/:controller/login' },
        logout: { method: 'POST', uri: '/:controller/logout' },
    }, {
        group: true,            // Define group REST vs singleton (default true)
        service: 'service',     // Prefix URI with service name
        set: 'singleton|group', // Base set of REST routes. Defaults to 'group'
        tunnel: 'ModelName'     // Tunnel requests through specified model
    })

    APIs can have the properties {
        base        Base url to use instead of the config.api
        body        Post body data
        clear       Clear prior feedback
        feedback    If true, emit feedback on success. Default, emit only on errors.
        invoke: fn, Function to invoke instead of issuing URI request
        log         Set to true to trace the request and response
        method      HTTP method
        nologout    Don't logout if response is 401
        noparse     Don't json parse any JSON response
        noprefix    Don't prefix the URL. Use the window.location host address.
        progress    If true, show progress bar.
        raw         If true, return the full response object (see below). If false, return just the data.
        refresh     To control cache refresh
        throw       If false, do not throw on errors
        uri         URI template
    }

    Callers invoke as:

    import Model from 'Model'
    Model.method(fields, options)

    Options:
        offset      Starting offset for first row
        limit       Limit of rows to return
 */

import blend from '@/paks/js-blend'
import clone from '@/paks/js-clone'
import UUID from '@/paks/js-uuid'
import {Net} from '@/paks/js-net'
import {State} from '@/paks/vu-state'
import {navigate} from '@/paks/vu-app/Utils.js'
import {Auth} from '@/paks/vu-auth/Auth.js'
import {allow, deny, titlecase} from '@/paks/js-polyfill'

/*
    Default routes
 */
const GroupRest = {
    create: {method: 'POST', uri: '/:controller/create'},
    get: {method: 'POST', uri: '/:controller/get'},
    init: {method: 'POST', uri: '/:controller/init'},
    find: {method: 'POST', uri: '/:controller/find'},
    remove: {method: 'POST', uri: '/:controller/remove', nomap: true},
    update: {method: 'POST', uri: '/:controller/update'},
}

const SingletonRest = {
    create: {method: 'POST', uri: '/:controller/create'},
    get: {method: 'POST', uri: '/:controller/get'},
    init: {method: 'POST', uri: '/:controller/init'},
    remove: {method: 'POST', uri: '/:controller/remove', nomap: true},
    update: {method: 'POST', uri: '/:controller/update'},
}

var Stats = { }

class Rest {
    /*
        WARNING: APIs become top-level instance properties
     */
    constructor(name, customApis = {}, modifiers = {group: true}) {
        this._net = new Net()
        this._name = name.toLowerCase()
        this._model = titlecase(name.split(' ')[0])
        this._clientId = UUID()
        modifiers = this._modifiers = Object.assign({}, modifiers)
        modifiers.service = modifiers.service || ''
        this._service = modifiers.service || ''

        let set
        if (modifiers.set == 'singleton') {
            set = SingletonRest
        } else if (modifiers.set != 'none') {
            set = GroupRest
        }
        this._apis = blend(clone(set), customApis)

        for (let [action, api] of Object.entries(this._apis)) {
            if (typeof api == 'function') {
                this[action] = api
            } else {
                api.action = action
                this[action] = this.createAction(api, action)
            }
            if (!customApis[action] && api.context === undefined) {
                api.context = modifiers.context
            }
        }
    }

    static setConfig(config, callback) {
        Rest.config = config
        Rest.version = config.version
        Rest.callback = callback || restCallback
        Rest.tunnel = config.tunnel

        if (config.rest) {
            Stats.enable = true
            Stats.start = Date.now()
            Stats.count = 0
            Stats.period = config.rest.period
            Stats.max = config.rest.max
        }
    }

    /*
        Invoke callbacks before and after request
     */
    async invokeCallback(reason, args) {
        let {result} = args
        let fetch = false
        if (args.api.callback) {
            await args.api.callback(reason, args)
        }
        if (this._modifiers.callback) {
            await this._modifiers.callback(reason, args)
        }
        if (Rest.callback) {
            args.controller = this._name
            args.model = this._model
            args.cache = this._modifiers.cache
            ;({fetch, result} = await Rest.callback(reason, args))
        }
        return {fetch, result}
    }

    createAction(api, action) {
        return async function (fields, options = {}) {
            fields = clone(fields || {})
            if (api.context) {
                fields = api.context(fields)
            }
            if (this._modifiers.context) {
                fields = this._modifiers.context(fields)
            }
            if (api.invoke) {
                return api.invoke(fields, options)
            }
            let params = {action, api, fields, options}
            let {fetch, result} = await this.invokeCallback('before', params)
            if (!fetch) {
                return result
            }
            fields = params.fields
            let mark = new Date()
            let {args, uri} = await this.prepRemote(api, fields, options)

            result = await this._net.fetch(uri, args)

            /*
                Unwrap paged data
             */
            if (result && typeof result == 'object' && result.data && (result.paged || result.next || result.prev)) {
                let {next, prev} = result
                result = result.data
                result.next = next
                result.prev = prev
            }
            let elapsed = (new Date() - mark) / 1000
            ;({result} = await this.invokeCallback('after', {action, api, fields, options, result, elapsed}))
            return result
        }
    }

    /*
        Replace the route :NAME fields with param values
        */
    async prepRemote(api, fields, options) {
        let name = this._name
        let service = this._service
        let serviceOverride = false
        let modifiers = this._modifiers
        let uri = api.uri.replace(/:\w*/g, function (match, lead, tail) {
            let field = match.slice(1)
            if (field == 'controller') {
                if (Rest.tunnel && modifiers.tunnel) {
                    return Rest.tunnel
                }
                return name
            }
            if (field == 'service') {
                serviceOverride = true
                return service
            }
            return fields && fields[controller] ? fields[controller] : ''
        })

        if (!serviceOverride && this._service) {
            let prefix = (typeof this._service == 'function') ? this._service() : this._service
            uri = prefix + uri
        }
        let args = Object.assign({}, api, allow(options, ['feedback', 'log', 'nologout', 'refresh', 'throw']))
        let body = {}
        if (Rest.tunnel && this._modifiers.tunnel) {
            body._type = this._modifiers.tunnel
        }
        if (fields) {
            for (let [field, value] of Object.entries(fields)) {
                body[field] = value
            }
        }
        if (Object.keys(body).length > 0) {
            body = JSON.stringify(body)
            if (args.method == 'POST') {
                args.body = body
            } else {
                let sep = uri.indexOf('?') >= 0 ? '&' : '?'
                body = encodeURIComponent(body)
                uri = `${uri}${sep}${body}`
            }
        }
        args.headers = Object.assign(args.headers || {}, {
            Accept: 'application/json',
            Origin: window.location.origin,
            'Content-Type': 'application/json',
            'X-Client': this._clientId,
            'X-Version': Rest.version,
            'X-Host': Rest.config.host,
        })
        if (State.auth.assume) {
            args.headers['X-Assume'] = State.auth.assume
        }
        if (api.invokeType) {
            args.headers['X-Amz-Invocation-Type'] = api.invokeType
        }
        if (State.auth.tokens) {
            args.headers.Authorization = State.auth.tokens.idToken.jwtToken
        }
        args.base = api.base || Rest.config.api

        if (State.app.logging) {
            options.logging = options.logging || State.app.logging
        }
        //  Filter out options for js-net and pass the rest in the URL
        options = deny(options, ['feedback', 'log', 'nologout', 'refresh', 'throw', 'reload'])
        if (Object.keys(options).length) {
            if (options.next && typeof options.next == 'object') {
                options.next = JSON.stringify(options.next)
            }
            if (options.prev && typeof options.prev == 'object') {
                options.prev = JSON.stringify(options.prev)
            }
            let query = new URLSearchParams(this.getQuery(options)).toString()
            if (query) {
                let sep = uri.indexOf('?') >= 0 ? '&' : '?'
                uri = `${uri}${sep}${query}`
            }
        }
        return {args, uri}
    }

    getQuery(options) {
        let query = {}
        for (let [key, value] of Object.entries(options)) {
            if (value !== undefined) {
                if (typeof value == 'object') {
                    value = JSON.stringify(value)
                }
                query[key] = value
            }
        }
        return query
    }
}

async function restCallback(reason, args) {
    let {action, cache, fields, model, options, result} = args
    if (reason == 'before') {
        if (!cache) {
            return {fetch: true}
        }
        let refresh = options.refresh
        if (action == 'remove') {
            refresh = true
            State.remove(model, fields)
        }
        if (refresh == false || refresh == null) {
            switch (action) {
                case 'create':
                case 'init':
                case 'update':
                    break

                case 'get':
                    result = State.get(model, fields)
                    break

                case 'find':
                    result = State.find(model, fields)
                    break

                case 'remove':
                    State.remove(model, fields)
                    break

                default:
                    refresh = true
                    break
            }
        }
        if ((result == null || (action == 'find' && result.length == 0)) && refresh == null) {
            refresh = true
        }
        return {fetch: refresh, result}
    } else if (reason == 'after') {
        if (result != null && typeof result == 'object') {
            mapTypes(result)
        }
        if (cache && result && options.refresh != 'bypass') {
            switch (action) {
                case 'find':
                    if (Object.keys(fields || {}).length == 0) {
                        State.set(model, result)
                    }
                    break
                case 'create':
                case 'get':
                case 'update':
                    State.set(model, result)
                    break
            }
        }
        if (Stats.enable && State.auth.email == 'support@embedthis.com') {
            Stats.count++
            if (Stats.count > Stats.max) {
                console.log('Too many requests')
                await Auth.logout({redirect: true})
                navigate('/login')
            }
            if ((Date.now() - Stats.start) > Stats.period * 1000) {
                Stats.count = 0
            }
            // console.log('Count', Stats.count, (Date.now() - Stats.start) / 1000)
        }
        return {result}
    }
}

async function mapTypes(obj) {
    for (let [key, value] of Object.entries(obj)) {
        if (
            typeof value == 'string' &&
            value.length == 24 &&
            value.charAt(23) == 'Z' &&
            new Date(value).toJSON() == value
        ) {
            obj[key] = new Date(value)
        } else if (typeof value == 'object' && !(value instanceof Date || value instanceof RegExp || value == null)) {
            mapTypes(value)
        }
    }
}

export {Rest}
<script setup>
/*
    <VuSign
        :show       This sign is eligible to be shown. Will display if required or if not dismissed
        :required   If required is true, then will show if 'shown' is true
*/
import {onMounted, reactive, watch} from 'vue'
import {State} from '@/paks/vu-app'

const props = defineProps({
    body: String,
    color: String,
    name: String,
    required: Boolean,
    show: {type: Boolean, default: true},
    subtitle: String,
    title: String,
    type: String,
})

const page = reactive({display: false})
const sign = reactive(State.app.getSign(props.name))
defineExpose({dismiss, page})

watch(sign, updateSign, {deep: true})
watch(() => props.show, updateSign)
watch(() => props.required, updateSign)

onMounted(() => {
    updateSign()
})

function dismiss() {
    sign.dismiss = true
    updateSign()
}

function updateSign() {
    let prefs = State.auth.user
        ? State.auth.user.preferences || {signposts: true}
        : {signposts: true}
    if (State.config.features?.nav?.signposts === false) {
        prefs = {signposts: false}
    }
    page.display = props.show && (props.required || (prefs.signposts && !sign.dismiss))
}
</script>

<template>
    <v-card
        class="vu-sign"
        v-if="page.display"
        elevation="1"
        :class="color ? `border-${color}` : ''">
        <v-card-title class="sign-title" v-if="title || subtitle">
            <div>
                {{ title }}
                <span class="actions"><slot name="actions"></slot></span>
            </div>
            <div class="sign-subtitle">{{ subtitle }}</div>
            <v-btn v-if="!required" variant="plain" class="close" @click="dismiss">
                <v-icon icon="$close" class="small" />
            </v-btn>
        </v-card-title>
        <v-card-text class="sign-body">
            <slot></slot>
            {{ body }}
        </v-card-text>
    </v-card>
</template>

<style lang="scss">
.vu-sign {
    margin-bottom: 16px;
    border: 1px solid rgb(var(--v-theme-border)) !important;
    .sign-title {
        font-size: 1.8rem;
        font-weight: normal;
        margin-top: 6px;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgb(var(--v-theme-border-lighten-1));
        display: block;
    }
    .sign-subtitle {
        margin-top: 4px;
        font-size: 1rem;
    }
    .sign-body {
        color: rgb(var(--v-theme-text));
        font-weight: normal;
        font-size: 1rem;
        padding-bottom: 6px;
        line-height: 1.4em;
        p {
            margin-bottom: 16px;
        }
    }
    .actions {
        margin-left: 20px;
        .v-btn {
            margin-right: 10px !important;
            border: 1px solid rgb(var(--v-theme-border-lighten-1)) !important;
            background: none !important;
            box-shadow: none !important;
        }
    }
    .close {
        position: absolute;
        padding: 0 !important;
        min-width: 32px !important;
        top: 0;
        right: 0;
    }
    &.border-teal {
        border-top: solid 2px teal !important;
    }
    &.border-error {
        border-top: solid 2px rgb(var(--v-theme-error)) !important;
    }
    &.border-accent {
        border-top: solid 2px rgb(var(--v-theme-accent)) !important;
    }
    a {
        cursor: pointer;
    }
}
</style>

/*
    AppState.js -- Global state
 */

import {allow} from '@/paks/js-polyfill'
import blend from '@/paks/js-blend'
import {Log} from '@/paks/js-log'
import {State} from '@/paks/vu-state'
import {Account} from '@/paks/mgr-models'

const PersistState = ['context', 'bright', 'dark', 'prefers', 'signs', 'upgradeAttempt']

export default class AppState {
    api = null
    bright = null
    closing = null
    cognito = null
    context = {}
    dark = null
    display = null
    help = null
    lastAccess = new Date()
    local = false
    location = {
        hash: null,
        query: {},
        prior: null,
        url: null,
    }
    logging = null
    logo = null
    mobile = false
    needs = {
        repaint: null,
        reload: null,
    }
    nextTable = 1
    prefers = {}
    refresh = null
    schema = null
    signs = {}
    social = null
    started = new Date()
    title = null
    unsaved = null
    upgrading = false
    upgradeAttempt = 0
    versions = {}

    saveState(state) {
        return allow(state, PersistState)
    }

    loadState(state) {
        state = allow(state, PersistState)
        return state
    }

    async closeAccount() {
        try {
            this.closing = true
            await Account.close()
        } catch (err) {
            Log.error('Cannot close account', {err})
        } finally {
            this.closing = false
        }
    }

    dismissSign(name) {
        this.signs[name] = this.signs[name] || {}
        this.signs[name].dismiss = true
    }

    getCart() { return [] }

    getInvite() {
        return this.location.query.invite
    }

    getSign(name) {
        return (this.signs[name] = this.signs[name] || {})
    }

    getNextTable() {
        return this.nextTable++
    }

    get isSocial() {
        let loc = State.app.location || '/'
        return loc.url.indexOf('/auth/login/social') == 0 ? true : false
    }

    need(key) {
        return this.needs[key]
    }

    noNeed(key) {
        delete this.needs[key]
    }

    prefer(key, value) {
        return this.prefers[key]
    }

    setActivity() {
        this.lastAccess = new Date()
    }

    setApi(api) {
        if (!api) {
            api = `${window.location.host}/api`
        }
        this.api = api
    }

    setCognito(cognito) {
        this.cognito = cognito
    }

    async setDisplay(display) {
        this.display = display
        if (display.logo) {
            this.setLogo(display.logo)
        }
        if (display.features) {
            this.setFeatures(display.features)
        }
        if (display.theme) {
            this.setTheme(display.theme)
        }
        if (display.title) {
            this.setTitle(display.title)
        }
        if (display.version) {
            State.config.version = display.version
        }
        if (display.timeouts) {
            State.config.timeouts = Object.assign(State.config.timeouts || {}, display.timeouts)
        }
        this.fixViews(display)
        this.mobile = window.innerWidth <= 640
    }

    fixViews(parent) {
        let views = (parent.views || []).concat(parent.children || [])
        for (let view of views) {
            view.path = this.rebase(view.path, parent)
            if (view.redirect) {
                view.redirect = this.rebase(view.redirect, view)
            }
            if (view.views || view.children) {
                this.fixViews(view)
            }
        }
    }

    rebase(path, parent) {
        if (!path || path[0] == '/' || !parent) {
            return path
        }
        if (parent.path == '/') {
            return `/${path}`
        } else {
            return `${parent.path}/${path}`
        }
    }

    setFeatures(features) {
        State.config.features = blend(State.config.features || {}, features)
        if (State.config.features?.auth?.social) {
            this.setSocial(State.config.features.auth.social)
        }
    }

    setInvite(value) {
        return (this.location.query.invite = value)
    }

    setHelp() {}

    setLogo(logo) {
        this.logo = logo
    }

    setNeed(key, value) {
        if (value == undefined) {
            value = Date.now()
        }
        this.needs[key] = value
    }

    setPrefer(key, value) {
        this.prefers[key] = value
    }

    setSchema(schema) {
        this.schema = schema
    }

    setSocial(social) {
        this.social = social
    }

    setTheme(theme) {
        if (!theme.formats) {
            theme.formats = State.config.formats
        }
        if (!theme.login) {
            theme.login = State.config.login
        }
        State.config.theme = theme
    }

    setTitle(title) {
        this.title = title
    }

    setVersions() {}

    setContext(key, value) {
        if (typeof key == 'object') {
            for (let [k, v] of Object.entries(key)) {
                if (k != 'id') {
                    this.context[k] = v
                }
            }
        } else if (key != 'id') {
            this.context[key] = value
        }
    }

    /*
        Test a value against a patter with a default value if not defined
        Return true if the value matches the pattern
        Properties evaluated as:
            Number
            "string"
            /RegExp/
            "OP string" where OP is < <= >= > == !=
        }
        Group expression elements with:
            [ ] -- Return true if all are true
            { } -- Return true if any are true
        Returns true if any (or) of the values are true
        Return true if expression is not defined
     */
    testContext(value, pattern, defaultValue = true) {
        if (value == null) {
            return defaultValue
        }
        if (value === true) {
            return true
        }
        if (value === false) {
            return false
        }
        if (value === pattern) {
            return true
        }
        if (typeof pattern == 'string') {
            if (pattern[0] == '/' && pattern[pattern.length -1] == '/') {
                pattern = pattern.slice(1, -1)
                return new RegExp(pattern).test(value) ? true : false
            } else {
                let [op, pat] = pattern.split(' ')
                if (op) {
                    switch (op) {
                    case '<': 
                        return value < pat
                    case '<=':
                        return value <= pat
                    case '>':
                        return value > pat
                    case '>=':
                        return value >= pat
                    case '==':
                        return value == pat
                    case '!=':
                        return value != pat
                    }
                }
            }
        }
        if (Array.isArray(value)) {
            //  Implements "AND" of all items. One false and we're out.
            for (let item of value) {
                if (!testContext(value, item, defaultValue)) {
                    return false
                }
            }
            return true
        } else if (typeof value == 'object' && Object.keys(value).length > 0) {
            //  Implements "OR" of values. One true and we're in.
            for (let [key, pat] of Object.entries(value)) {
                if (this.testContext(this.context[key], pat, defaultValue)) {
                    return true
                }
            }
            return false
        }
        return defaultValue
    }
}
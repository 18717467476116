<script setup>
import {onMounted, onUnmounted, reactive, ref, watch} from 'vue'
import {
    State,
    can,
    clone,
    getModels,
    getRoute,
    getRoutes,
    getValue,
    navigate,
    waitRender,
} from '@/paks/vu-app'
import {getView, provision} from '@/paks/vu-dash/DashUtils.js'

const props = defineProps({
    board: Object,
    design: Boolean,
    view: Object,
    widget: {type: Object, default: {}},
})

const page = reactive({
    active: true,
    canEdit: null,
    currentRange: null,
    dashboardNames: [],
    expand: false,
    hasMetrics: null,
    loadingRange: null,
    multiCloud: null,
    range: {anchor: 'relative', period: 3600},
    refresh: 60,
    saving: null,
    showWidgetEdit: false,
    showBoardEdit: false,
})

const self = ref(null)
const {Dashboard, Manager} = getModels()

defineExpose({update})

onMounted(() => {
    let features = State.config.features || {}
    let board = props.board
    page.hasMetrics =
        features.dash.metrics && board.widgets.filter((w) => w.metric).length ? true : false
    page.canEdit = can('admin') && features.dash.edit
    page.dashboardNames = State.cache.dashboards.map((d) => d.name).sort()
    page.refresh = getValue(board.refresh || State.config?.timeouts?.dashboard || 60)

    //  DEPRECATE multiple
    page.multiCloud = features.widget?.multiCloud || features.cloud.multiple
    Object.assign(page.range, board.range)
    page.currentRange = clone(page.range)

    watch(
        () => page.range,
        () => rangeUpdated(),
        {deep: true}
    )
})

onUnmounted(() => {
    page.active = false
})

function update() {
    let el = self.value
    page.dashboardNames = State.cache.dashboards.map((d) => d.name).sort()
    Object.assign(page.range, props.board.range)
}

function percent() {
    return Math.max(0, 100 - (props.board.remaining / page.refresh) * 100)
}

async function rangeUpdated() {
    page.loadingRange = true
    await waitRender()
    if (State.auth.ready) {
        if (!page.currentRange || page.range.period != page.currentRange.period) {
            page.currentRange = clone(page.range)
            props.board.range = page.range
            State.app.setNeed('saveDash')
        }
        refreshData()
    }
    page.loadingRange = false
}

function refreshData() {
    State.app.setNeed('board', 'refreshData')
}

function reloadBoard() {
    State.app.setNeed('board', 'reload')
}

function compactWidgets() {
    State.app.setNeed('board', 'compact')
}

function editBoard() {
    State.app.setNeed('board', 'editBoard')
}

function editWidget() {
    State.app.setNeed('editWidget')
}

function expandWidgets() {
    State.app.setNeed('board', 'expand')
}

function showBoard() {
    let board = props.board
    let path = getRoute().query.path
    if (path) {
        navigate(path)
    } else {
        navigate('/')
    }
}

async function changeDashboard(name) {
    let dashboard = State.cache.dashboards.find((d) => d.name == name)
    await Dashboard.set(dashboard)
    State.app.setNeed('board', 'reload')
}

async function changeDesign() {
    navigate('/dashboards/list/editor', {design: true})
}

async function save() {
    page.saving = true
    let board = clone(props.board)
    if (board.type == 'page') {
        let {views, key} = getView(props.view.path)
        if (key >= 0) {
            if (board.componentName) {
                board.component = getRoutes().getComponent(board.componentName)
                delete board.componentName
            }
            views[key] = props.view
            views[key].board = board
            await provision({ui: State.app.display, update: true})
        }
    } else {
        board.range = page.range
        await Dashboard.set(board)
        await Dashboard.update(board)
    }
    page.saving = false
    State.app.setNeed('saveDash', false)
}
</script>

<template>
    <div ref="self" class="toolbar-widget">
        <v-toolbar
            density="compact"
            class="toolbar"
            :class="board.full && !board.design ? 'full' : ''">
            <div class="toolbar-icons">
                <vu-pick
                    v-if="
                        props.board.type != 'page' &&
                        page.canEdit &&
                        page.multiCloud &&
                        page.dashboardNames.length > 1 &&
                        !(State.app.needs.save && page.mobile)
                    "
                    align="right"
                    class="pick-dashboard mr-4"
                    :label="board.name"
                    :items="page.dashboardNames"
                    @click="changeDashboard" />

                <vu-date
                    v-if="true || page.hasMetrics"
                    v-model="page.range"
                    class="date-picker"
                    mode="date"
                    :loading="page.loadingRange"
                    size="small" />

                <v-btn
                    v-if="board?.widgets?.length && board.live"
                    variant="text"
                    class="icon reload"
                    v-tip.bottom="'Reload Data'"
                    @mousedown.stop="refreshData">
                    <v-progress-circular
                        :size="26"
                        :width="3"
                        :model-value="percent()"
                        color="primary-lighten-1">
                        {{ board.remaining }}
                    </v-progress-circular>
                </v-btn>

                <v-icon
                    v-if="page.canEdit && board.layout == 'grid' && !board.snap"
                    icon="$shrink"
                    class="icon"
                    size="large"
                    v-tip.bottom="'Compact Widgets'"
                    color="primary-lighten-1"
                    @mousedown.stop="compactWidgets" />
                <v-icon
                    v-if="page.canEdit && board.layout == 'grid'"
                    icon="$stretch"
                    class="icon"
                    size="large"
                    v-tip.bottom="'Expand Widgets'"
                    color="primary-lighten-1"
                    @mousedown.stop="expandWidgets" />

                <v-icon
                    v-if="page.canEdit"
                    icon="$dashboard"
                    class="icon"
                    size="large"
                    v-tip.bottom="'Modify'"
                    :color="true ? 'primary-lighten-1' : ''"
                    @mousedown.stop="editBoard(board)" />

                <v-icon
                    v-if="page.canEdit"
                    icon="$plusBoxOutline"
                    class="icon"
                    size="large"
                    v-tip.bottom="'Add Widget'"
                    :color="true ? 'primary-lighten-1' : 'secondary'"
                    @mousedown.stop="editWidget({})" />

                <v-icon
                    v-if="page.canEdit && !props.design"
                    icon="$tools"
                    class="icon"
                    size="large"
                    v-tip.bottom="board.design ? 'Designer' : 'Show'"
                    :color="board.design ? 'primary' : 'accent'"
                    @mousedown.stop="changeDesign()" />

                <v-icon
                    v-if="props.design"
                    icon="$launch"
                    class="icon"
                    size="large"
                    :v-tip.bottom="`Show board?.type == 'dashboard' ? 'Dashboard' : 'Page'}`"
                    :color="true ? 'primary-lighten-1' : 'secondary'"
                    @mousedown.stop="showBoard()" />

                <v-btn
                    v-if="State.app.needs.saveDash"
                    size="small"
                    class="dash-save"
                    :loading="page.saving"
                    @mousedown.stop.stop="save">
                    Save
                </v-btn>

                <v-btn
                    v-if="State.app.needs.saveDash"
                    class="dash-btn"
                    variant="outlined"
                    size="small"
                    @mousedown.stop.stop="reloadBoard">
                    Reset
                </v-btn>

                <v-btn
                    v-if="page.item && !State.app.needs.save"
                    class="extras"
                    variant="outlined"
                    @mousedown.stop="navigate('/devices')">
                    {{ page.item }}
                </v-btn>
            </div>
        </v-toolbar>
    </div>
</template>

<style lang="scss">
.toolbar-widget {
    display: inline-block;
    padding: 8px 8px 8px 0;
    min-width: 460px;
    z-index: 10;
    .v-toolbar {
        width: fit-content !important;
        max-width: unset !important;
        border: none !important;
        background: rgb(var(--v-theme-background-lighten-1)) !important;
        .v-btn {
            font-size: 0.8rem;
        }
        .v-toolbar__content {
            overflow: visible;
        }
        .dashname {
            flex-grow: 0;
        }
        .pick-dashboard {
            cursor: pointer;
            .v-btn {
                font-size: 1rem;
            }
        }
        .vu-pick {
            margin-right: 8px !important;
            .v-btn {
                font-size: 0.75rem;
            }
        }
        .toolbar-icons {
            display: inline-flex;
            vertical-align: middle;
            align-items: flex-end;
            height: 36px;
            padding-bottom: 4px;
            .icon {
                margin-right: 8px;
                height: 26px;
                font-size: 22px;
                padding-bottom: 8px;
                cursor: pointer;
                @media (max-width: 640px) {
                    margin-right: 8px;
                }
            }
            .reload {
                background: none !important;
                box-shadow: none !important;
                border: none !important;
                height: 20px !important;
                width: 20px !important;
                min-width: 0 !important;
                margin: 0 4px 10px 0 !important;
            }
            .reload:hover {
                color: rgba(0, 0, 0, 0);
            }
            .extras {
                _font-size: 1rem;
                border: 1px solid rgb(var(--v-theme-border));
                _border: none;
                color: rgb(var(--v-theme-primary));
            }
        }
        .date-range {
            .v-btn {
                min-width: unset !important;
                font-size: 0.75rem;
            }
        }
        .custom-input {
            width: 95px !important;
        }
        .dash-btn {
            margin: 0 10px 4px 4px !important;
            border: solid 1px rgb(var(--v-theme-border));
            background: white;
        }
        .dash-save {
            margin: 0 10px 4px 4px !important;
            background: rgb(var(--v-theme-accent));
            color: white;
        }
    }
    .v-toolbar.full {
        padding-left: 52px;
    }
}
</style>

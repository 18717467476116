<script setup>
/*
    vu-tabs -- Construct a horizontal tabbed menu from the route tables for the parent component
*/
import {getCurrentInstance, onMounted, reactive, ref, watch} from 'vue'
import {Routes, State, can, getRouter, navigate, toTitle} from '@/paks/vu-app'

const {proxy: self} = getCurrentInstance()

const props = defineProps({
    title: String,
    views: null,
})

const page = reactive({
    cookies: [],
    hasTabs: false,
    items: [],
    loading: false,
    tab: 0,
    title: props.title,
})

const tabs = ref(null)

watch(() => self.$route, renderMenu, {immediate: true})

onMounted(() => renderMenu)

function renderMenu() {
    let route = self.$route
    let meta = route.meta
    page.title = meta.title
    page.hasTabs = State.auth.ready && meta?.view?.tabs ? true : false
    page.tab = 0
    makeMenu()
    if (State.config.features.nav?.cookies) {
        makeCookies()
    }
}

function makeMenu() {
    let route = self.$route
    let meta = route.meta
    let view = meta.view || {}
    let items = []
    let tabs = view.parentTabs || view.tabs || []
    for (let tab of tabs) {
        if (tab.role && !can(tab.role)) continue
        let to = Routes.template(tab.path, State.app.context)
        let item = {to, name: tab.name}
        if (item.to.indexOf(':') >= 0 && tab.enable !== true) {
            //  Vuetify bug prevents proper highlighting so only push enabled tabs
            // item.disabled = true
        } else {
            items.push(item)
        }
    }
    page.items.splice(0, page.items.length, ...items)
    page.title = Routes.template(route.meta.title, self.$route.params)
    page.tab = items.findIndex((i) => i.name == route.name)
    if (page.tab < 0) {
        page.tab = items.findIndex(i => route.path.indexOf(i.to) == 0)
    }
}

function makeCookies() {
    let path = window.location.pathname + window.location.hash.replace(/^#\//, '')
    let cookies = []
    let parts = []
    for (let part of path.split('/')) {
        part = Routes.template(part, self.$route.params)
        parts.push(part)
        if (part) {
            cookies.push({
                title: toTitle(part),
                to: parts.join('/'),
            })
        }
    }
    if (cookies.length) {
        cookies[cookies.length - 1].last = true
    }
    page.cookies = cookies
}

function jump(cookie) {
    let route = self.$route
    let context = Object.assign({}, route.params)
    let to = Routes.template(cookie.to, context)
    try {
        navigate(to)
    } catch (err) {}
}
</script>

<template>
    <v-toolbar color="surface" v-if="page.hasTabs" flat class="v-nav">
        <v-toolbar-title v-if="page.title">{{ page.title }}</v-toolbar-title>
        <v-toolbar-items v-if="page.items.length > 0">
            <v-tabs v-model="page.tab" ref="tabs">
                <slot>
                    <v-tab
                        ripple
                        v-for="item of page.items"
                        @click="navigate(item.to)"
                        :key="item.name"
                        :disabled="item.disabled">
                        {{ item.name }}
                    </v-tab>
                </slot>
            </v-tabs>
        </v-toolbar-items>

        <v-spacer v-if="!State.app.mobile" />
        <!--
            <v-btn variant="outlined" class="reload" @click="reload" :loading="page.loading">
                <v-icon icon="$reload" class="small" />
            </v-btn>
        -->
        <div class="cookies">
            <span class="cookie" v-for="cookie of page.cookies" :to="cookie.to" :key="cookie.title">
                <span @click.stop.prevent="jump(cookie)">{{ cookie.title }}</span>
                <span class="sep" v-if="!cookie.last">/</span>
            </span>
        </div>
    </v-toolbar>
</template>

<style lang="scss">
header.v-nav {
    height: 64px !important;
    background-color: rgb(var(--v-theme-form)) !important;
    border-bottom: 1px solid rgb(var(--v-theme-border-lighten-1)) !important;
    .v-toolbar-title {
        padding-top: 6px;
        min-width: 100px;
        padding-left: 8px;
        font-size: 28px;
        color: rgb(var(--v-theme-text));
        //  Changes to align in the builder tokens page
        line-height: normal !important;
        margin-inline-start: 12px;
        @media (min-width:640px) {
            min-width: 300px;
        }
        @media (max-width:640px) {
            padding-left: 24px !important;
        }
    }
    @media (max-width:640px) {
        .v-toolbar-items {
            position: fixed;
            bottom: 8px;
            left: 0;
            right: 0;
            justify-content: center;
            margin-right: 10px;
            .v-btn {
                padding: 0 4px;
                font-size: 0.75rem;
            }
            .v-tab__slider {
                bottom: 10px;
            }
        }
    }
    .reload {
        height: 30px !important;
        min-width: 30px !important;
        padding: 0 !important;
        margin-right: 10px;
        border-color: rgb(var(--v-theme-border-lighten-1));
    }
    .cookies {
        padding: 20px 14px 0 0;
        .cookie {
            color: rgb(var(--v-theme-text-lighten-1));
            font-size: 16px;
            cursor: pointer;
            .sep {
                padding: 0 8px 0 8px;
                color: rgb(var(--v-theme-text-lighten-2));
            }
        }
    }
    .v-toolbar__content {
        padding: 0 16px 0 16px;
    }
    .v-btn.v-slide-group-item--active {
        color: rgb(var(--v-theme-text));
    }
    .v-btn:not(.v-slide-group-item--active) {
        color: rgb(var(--v-theme-text-lighten-2));
    }
    .v-tab__slider {
        color: rgb(var(--v-theme-accent)) !important;
        height: 3px !important;
    }
}
</style>

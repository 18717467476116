/*
    Manager.js - Manager model
 */

import {Rest} from '@/paks/vu-app'

export default new Rest(
    'Manager',
    {
        provision: {method: 'POST', uri: '/:controller/provision'},
    },
)

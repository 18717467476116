<script setup>
import {onMounted, reactive} from 'vue'
import {State, getModels} from '@/paks/vu-app'
import Json5 from 'json5'

const props = defineProps({
    dashboard: Object,
})

const emit = defineEmits(['input'])
const {Dashboard} = getModels()

const page = reactive({
    dname: State.config.builder,
    filename: null,
    saving: null,
})

onMounted(() => {
    page.filename = `${props.dashboard.name}.json5`
})

async function save() {
    await exportDash(props.dashboard)
    emit('input')
}

async function exportDash(dashboard) {
    dashboard = Dashboard.clean(dashboard)
    delete dashboard.id
    let data = Json5.stringify(dashboard, null, 4)
    let blob = new Blob([data], {type: 'text/plain'})
        let link = document.createElement("a")
        link.download = page.filename
        if (window.webkitURL != null) {
            link.href = window.webkitURL.createObjectURL(blob)
        } else {
            link.href = window.URL.createObjectURL(blob)
            link.style.display = "none"
            document.body.appendChild(link)
        }
        link.click()
}
</script>

<template>
    <vu-form
        class="export-dash"
        help="/doc/ui/dashboard/dashboard-export.html"
        :data="page"
        :save="save"
        :title="`Export ${page.dname}`">

        <vu-input
            v-model="page.filename"
            placeholder="Filename"
            label="Export Filename"
            type="text" />

        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Export</v-btn>
            <v-btn size="small" color="none" @click="emit('input')">Cancel</v-btn>
        </div>
    </vu-form>
</template>

<style lang="scss"></style>

<script setup>
import {User} from '@/paks/mgr-models'
import {useTheme} from 'vuetify'
import {reactive} from 'vue'
import {Auth, State, clone, navigate} from '@/paks/vu-app'

const page = reactive({
    bright: State.app.bright || 'auto',
    rules: [],
    saving: false,
})
const user = reactive(clone(State.auth.user))

const theme = useTheme()
const social = State.app.social ? true : false

async function save() {
    setMode()
    if (user.first && user.last) {
        await Auth.setUser(user.id, {
            given_name: user.first,
            family_name: user.last,
        })
        await User.update({
            id: user.id,
            first: user.first,
            last: user.last,
            email: user.email,
            preferences: user.preferences,
        })
    }
    navigate('/')
}

function setMode() {
    let mode = page.bright
    State.app.bright = mode
    if (mode == 'auto') {
        mode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    }
    State.app.dark = mode == 'dark'
    theme.global.name.value = mode
}
</script>

<template>
    <vu-form
        :data="page"
        :save="save"
        help="/doc/ui/account/profile.html"
        title="Personal Profile"
        class="page-form ma-5">
        <vu-input v-model="user.first" label="First Name" :disabled="social" type="text" />
        <vu-input v-model="user.last" label="Last Name" :disabled="social" type="text" />
        <vu-input v-model="user.email" label="Email" :disabled="true" type="text" />
        <vu-input
            type="radio"
            v-model="page.bright"
            label="Dark Mode"
            :items="{Light: 'light', Dark: 'dark', Auto: 'auto'}" />
        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn size="small" color="none" @click="navigate('/')">Cancel</v-btn>
        </div>
    </vu-form>
</template>

/*
    index.js - Wrapper around fetch with timeouts and cancel

    net.fetch(uri, {
        clear       Clear prior feedback
        feedback    If false, never emit feedback. If true, emit feedback on success. Otherwise on errors.
        body        Post body data
        log         Set to true to trace the request and response
        method      HTTP method
        nologout    Don't logout if response is 401
        noparse     Don't json parse any JSON response
        progress    If true, show progress bar.
        throw       If false, do not throw on errors
    })

    REST services should return
        status: 200, Content-Type: application/json, data: json
        status: 400, Content-Type: text/plan, data: error-message-string
        status: 500, Content-Type: text/plan, data: error-message-string
    }

    Will throw an exception for all errors (non-200, timeout) by default. Set {throw:false} to suppress.
    This automatically displays progress and filters response feedback messages if required.

    Fetch doc: https://github.github.io/fetch/
 */

import Json from '@/paks/js-json'
import {NetError} from '@/paks/js-error'
import {Log} from '@/paks/js-log'
import {State, Store} from '@/paks/vu-state'
import {Feedback} from '@/paks/vu-feedback'
import {Progress} from '@/paks/vu-progress'

class Net {
    static setConfig(config, notify) {
        Net.config = config || {}
        this.notify = notify || netCallback
    }

    async fetch(url, options = {}) {
        this.config = Object.assign({timeouts: {http: 30}, api: ''}, Net.config)
        this.notify = this.notify || Net.notify
        if (options.clear) {
            await this.callback('clear')
        }
        if (!url.startsWith('http')) {
            let api = options.api || options.base || this.config.api || ''
            if (api.indexOf('http') == 0) {
                url = api + url
            } else {
                url = location.origin + api + url
            }
        }
        if (options.progress) {
            await this.callback('start')
        }
        if (options.log) {
            Log.trace('Fetch Request', {level: 0, options})
        }
        /*
            Issue request with timeout and retries
         */
        let state = {url, timeout: null}
        let response = await Promise.race([
            this.fetchRetry(state, options),
            this.timeout(state, this.config.timeouts?.http * 1000),
        ])
        clearTimeout(state.timeoutHandle)

        return this.parseResponse(url, options, response)
    }

    /*
        Issue a request with retries
     */
    async fetchRetry(state, options = {}) {
        let url = state.url
        let args = Object.assign({}, options)
        if (!args.method) {
            args.method = 'POST'
        }
        let retries = options.retries || 0
        args.mode = args.mode || 'cors'
        args.credentials = args.credentials === false ? undefined : args.credentials || 'include'
        let response
        let retry = 0
        do {
            try {
                response = await fetch(url, args)
                if (!response) {
                    throw new Error('No response')
                }
                break
            } catch (err) {
                if (state.timeout) {
                    // Request timed out. Promise fulfilled by timeout already
                    return
                }
                if (retries <= 0) {
                    break
                }
                Log.info(`Request ${url} failed, retry ${retry} ${err.message}`, {err})
            }
            retry++
        } while (--retries > 0)
        return response
    }

    async parseResponse(url, options, response) {
        let body, reader, status
        if (response) {
            status = response.status
            if (options.stream) {
                body = response.body.getReader()
            } else if (options.blob) {
                body = await response.blob()
            } else if (response.text) {
                body = await response.text()
                let contentType
                if (response.headers && response.headers.get) {
                    contentType = response.headers.get('Content-Type')
                }
                if (!body) {
                    body = null
                } else if (
                    body &&
                    contentType &&
                    !options.noparse &&
                    (contentType.indexOf('application/json') == 0 ||
                        contentType.indexOf('application/x-amz-json-1.0') == 0)
                ) {
                    try {
                        body = JSON.parse(body, Json.decode)
                    } catch (err) {
                        Log.error(`Cannot parse json response`, {body})
                    }
                }
            }
        } else {
            status = 445
        }
        if (status != 200 && status != 204) {
            if (status == 401 && options.nologout !== true) {
                await this.callback('logout')
                return null
            }
            if (options.feedback !== false) {
                response = response || {status}
                body = body || 'Network Connection Error'
                await this.callback('feedback', response, body)
            }
        }
        if (options.progress) {
            await this.callback('stop')
        }
        if (options.log) {
            Log.trace('Fetch Response', {status, body})
        }
        await this.callback('response', response, body)

        if (status != 200 && status != 204 && options.throw !== false) {
            if (typeof body == 'object' && body.message) {
                body = body.message
            }
            throw new NetError(body || 'Cannot complete operation')
        }
        return status == 200 ? body : null
    }

    async get(url, options = {}) {
        options = Object.assign({}, options)
        options.method = 'GET'
        return await this.fetch(url, options)
    }

    async post(url, options = {}) {
        options.method = 'POST'
        return await this.fetch(url, options)
    }

    async callback(reason, args, data) {
        if (this.notify) {
            try {
                await this.notify(reason, args, data)
            } catch (e) {
                print(e)
            }
        }
    }

    /*
        Fetch timeouts. The fetch API does not implement timeouts or cancel (Ugh!)
     */
    timeout(state, delay) {
        return new Promise(function (resolve, reject) {
            state.timeoutHandle = setTimeout(function () {
                state.timeout = true
                resolve({
                    body: 'Request timed out, please retry',
                    status: 444,
                })
            }, delay)
        })
    }
}

export {Net}

async function netCallback(reason, response, data) {
    if (reason == 'response' && response && response.headers) {
        let version = response.headers.get('X-Upgrade')
        if (version) {
            let sem = new SemVer()
            if (sem.getVer(version) > sem.getVer(State.config.version)) {
                /*
                    If the service is running a newer, incompatible version, try to upgrade (3 times max).
                */
                if (!State.app.upgrading && State.app.upgradeAttempt <= 3) {
                    State.app.upgradeAttempt = State.app.upgradeAttempt + 1
                    Store.save()
                    //  This will reload the browser
                    window.location.replace(`${window.location.href}?upgrading`)
                }
            }
        } else if (State.app.upgradeAttempt > 0) {
            State.app.upgradeAttempt = 0
        }
    } else if (reason == 'clear') {
        Feedback.clear()
    } else if (reason == 'feedback') {
        Feedback.response(response, data)
    } else if (reason == 'login' || reason == 'logout') {
        State.ref.router.push({path: '/login'}).catch(() => {})
    } else if (reason == 'start') {
        Progress.start()
    } else if (reason == 'stop') {
        Progress.stop()
    }
}

<script setup>
/*
    Main.vue -- Home page 
    import {} from 'manager'
 */
</script>

<template>
    <v-app>
        <vu-manager />
    </v-app>
</template>

<style lang="scss">
:root {
    font-size: var(--v-font-size, '14px');
    font: 'Roboto', sans-serif;
    background-color: rgb(var(--v-theme-background));
}
</style>

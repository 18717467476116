<script setup>
/*
    WidgetBasic - Basic widget configuration
 */
import {onBeforeMount, reactive, watch} from 'vue'
import {State, blend, titlecase} from '@/paks/vu-app'
import Json5 from 'json5'

const props = defineProps({
    widget: Object,
})

const page = reactive({
    dashboard: State.cache.dashboard,
    properties: [],
    rules: [],
    select: {},
    showColors: {},
    tableFields: null,
    types: null,
    widget: {},
})

const WidgetTypes = [
    {value: 'audo', label: 'Audio'},
    {value: 'button', label: 'Button'},
    {value: 'event', label: 'Event'},
    {value: 'form', label: 'Form'},
    {value: 'gauge', label: 'Gauge'},
    {value: 'graph', label: 'Graph'},
    {value: 'image', label: 'Image'},
    {value: 'input', label: 'Input'},
    {value: 'label', label: 'Label'},
    {value: 'led', label: 'Led'},
    {value: 'metric', label: 'Metric Table'},
    {value: 'numeric', label: 'Numeric'},
    {value: 'progress', label: 'Progress'},
    {value: 'set', label: 'Service Overview'},
    {value: 'shape', label: 'Shape'},
    {value: 'sign', label: 'Sign'},
    {value: 'table', label: 'Table'},
    {value: 'tabs', label: 'Tabs'},
    {value: 'toolbar', label: 'Toolbar'},
]

const InputTypes = [
    {value: 'checkbox', title: 'Checkbox'},
    {value: 'combo', title: 'Combination Box'},
    {value: 'date', title: 'Date'},
    {value: 'file', title: 'File'},
    {value: 'password', title: 'Password'},
    {value: 'radio', title: 'Radio'},
    {value: 'select', title: 'Select'},
    {value: 'slider', title: 'Slider'},
    {value: 'switch', title: 'Switch'},
    {value: 'text', title: 'Text Field'},
    {value: 'textarea', title: 'Text Area'},
]

const Fields = [
    {name: 'accept', hint: ''},
    {name: 'datetime', __hint: 'Date or time'},
    {name: 'field', hint: 'Database item field to receive changes'},
    {name: 'fields', hint: 'Table fields definition (JSON)'},
    {name: 'footer', hint: ''},
    {name: 'form', hint: ''},
    {name: 'format', hint: ''},
    {name: 'header', hint: ''},
    {name: 'items', hint: ''},
    {name: 'label', hint: ''},
    {name: 'max', hint: ''},
    {name: 'min', hint: ''},
    {name: 'multiple', hint: ''},
    {name: 'pivot', hint: 'Enter pivot column'},
    {name: 'placeholder', hint: ''},
    {name: 'prefix', hint: ''},
    {name: 'presentation', hint: ''},
    {name: 'rows', hint: ''},
    {name: 'subtitle', hint: ''},
    {name: 'suffix', hint: ''},
    {name: 'text', hint: ''},
    {name: 'timezone', hint: ''},
    {name: 'url', hint: ''},
    {name: 'validate', hint: ''},
    /*
        name, hint, type: checkbox, property: 'toolbar'
        Checkbox
        Schema
        toolbar: {
            dashboards: true,
            range: true,
            reload: true,
            design: true,
            expand: true,
            reset: true,
            dashboard: true,
            create: true,
        }
     */
]

const Select = {
    audio: {
        url: true,
    },
    button: {
        header: true,
        text: true,
        footer: true,
    },
    event: {
        max: true,
    },
    gauge: {
        footer: true,
        header: true,
        min: true,
        max: true,
    },
    graph: {
        header: true,
        footer: true,
        min: true,
        max: true,
        // TODO: types: bar, line, scatter, bubble, pie, doughnut, polarArea, radar
        presentation: false,
    },
    form: {
        form: true,
    },
    image: {
        url: true,
    },
    input: {
        field: true,
        form: true,
        label: true,
        placeholder: true,
        type: true,
    },
    label: {
        header: true,
        footer: true,
        text: true,
    },
    led: {
        header: true,
        footer: true,
    },
    list: {
        header: true,
        footer: true,
    },
    metric: {
        fields: true,
        header: true,
        pivot: true,
        subtitle: true,
    },
    numeric: {
        footer: true,
        format: true,
        header: true,
        prefix: true,
        suffix: true,
    },
    progress: {
        header: true,
        footer: true,
        min: true,
        max: true,
    },
    set: {},
    shape: {},
    sign: {
        header: true,
    },
    table: {
        fields: true,
        header: true,
        pivot: true,
        subtitle: true,
    },
    tabs: {
        header: true,
        items: true,
    },

    toolbar: {},
}

const SelectInput = {
    checkbox: {},
    combo: {
        items: true,
        multiple: 'switch',
    },
    date: {
        datetime: ['date', 'time', 'datetime'],
        prefix: true,
        suffix: true,
        timezone: true,
    },
    file: {
        accept: true,
        multiple: 'switch',
    },
    password: {
        prefix: true,
        suffix: true,
    },
    radio: {
        items: true,
    },
    select: {
        items: true,
        multiple: 'switch',
    },
    slider: {
        max: true,
        min: true,
        prefix: true,
        suffix: true,
    },
    switch: {},
    text: {
        prefix: true,
        suffix: true,
        validate: true,
    },
    textarea: {
        rows: true,
        validate: true,
    },
}

const emit = defineEmits(['apply'])

watch(() => page.widget?.namespace, prepFields)
watch(() => page.widget?.input, prepFields)

onBeforeMount(() => {
    let widget = (page.widget = props.widget)
    //  REPAIR
    if (widget.type == 'text') {
        widget.type = 'label'
    }
    if (widget.fields) {
        if (typeof widget.fields != 'string') {
            let fields = widget.fields.filter((f) => f)
            if (typeof fields[0] != 'string') {
                widget.fields = Json5.stringify(fields)
            }
        }
    }
    page.types = WidgetTypes.sort(sortByLabel)
    if (!page.widget.id) {
        changeType()
    }
    prepFields()
})

function changeType(v) {
    let widget = page.widget
    widget.max = widget.min = null
    widget.prefix = widget.suffix = null
    if (widget.type == 'shape' && !widget.id && widget.css.length == 0) {
        //  Create a default box
        widget.css.push({name: 'border', value: 'solid 1px black'})
    }
    prepFields()
}

function prepFields() {
    let widget = page.widget
    let select = blend({}, Select)
    if (widget.type == 'input' && SelectInput[widget.input]) {
        select.input = blend(select.input, SelectInput[widget.input])
    }
    if (State.cache.dashboard.layout == 'exact') {
        select[widget.type].z = true
    }
    for (let field of Fields) {
        if (widget[field] != null) {
            select[widget.type][field] = true
        }
    }
    page.select = select
}

function sortByLabel(a, b) {
    if (a.label < b.label) {
        return -1
    } else if (b.label < a.label) {
        return 1
    }
    return 0
}

function getRules(field) {
    if (field == 'field' || field == 'label') {
        return page.rules.required
    }
    return []
}
</script>

<template>
    <v-container fluid class="widget-style pa-0">
        <vu-input
            v-model="page.widget.type"
            item-value="value"
            item-title="label"
            label="Widget Type"
            type="select"
            :items="page.types"
            :rules="page.rules.required"
            @input="changeType" />

        <vu-input
            v-if="page.widget.type == 'input'"
            v-show="page.select[page.widget.type]?.type"
            v-model="page.widget.input"
            label="Widget Input Type"
            type="select"
            :items="InputTypes" />

        <vu-input
            v-model="page.widget.title"
            label="Widget Title"
            type="text"
            :clearable="true"
            :placeholder="page.widget.id ? '' : 'Blank for default title'" />

        <vu-input
            v-for="field of Fields"
            v-show="page.select[page.widget.type]?.[field.name]"
            v-model="page.widget[field.name]"
            :clearable="true"
            :name="titlecase(field.name)"
            :label="titlecase(field.name)"
            :placeholder="field.hint"
            :items="
                Array.isArray(page.select[page.widget.type][field.name])
                    ? page.select[page.widget.type][field.name]
                    : []
            "
            :rules="getRules(field.name)"
            :type="Array.isArray(page.select[page.widget.type][field.name]) ? 'select' : 'text'" />
    </v-container>
</template>

<style lang="scss">
.widget-style {
    h2 {
        font-size: 1.4rem;
        font-weight: normal;
    }
    .v-alert {
        width: 100%;
    }
    label {
        color: rgb(var(--v-theme-text));
        font-weight: normal;
        font-size: 1.2rem;
    }
    .color-picker {
        margin: 6px 0 16px 0;
        border: solid 1px rgb(var(--v-theme-border));
        max-width: 100% !important;
        width: 100% !important;
    }
    td.editable {
        padding: 4px 7px 4px 7px;
        background: rgba(220, 255, 220, 0.4) !important;
        margin: 2px;
        cursor: text;
        &:focus {
            outline: 2px solid rgba(0, 0, 255, 0.5) !important;
            background: white !important;
            width: calc(100% - 4px);
        }
    }
    .table-cell {
        padding: 4px !important;
        .cell-attribute {
            padding: 3px;
            background: rgba(50, 50, 50, 0.1);
            color: black;
        }
    }
    .table-cell.table-col-name {
        .cell-value {
            display: inline-block;
            width: 100%;
            margin: 1px;
            padding: 3px;
        }
    }
    .table-cell.table-col-value {
        .cell-value {
            display: inline-block;
            width: calc(100% - 20px);
            margin: 1px;
            padding: 3px;
            padding-right: 0;
        }
    }
    .color-icon {
        margin-top: 2px;
        float: right;
        width: 16px;
    }
    .table-title {
        font-size: 1.6rem !important;
    }
    .add {
        font-size: 12px;
    }
    .divider {
        font-size: 1.2rem !important;
        margin-bottom: 16px !important;
        clear: both;
        width: 100%;
    }
    .v-label {
        font-size: 0.9rem;
    }
}
</style>

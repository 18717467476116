export default class ChatClass {
    provide(provider) {
        this.provider = provider
    }

    async init(config) {
        if (this.provider) {
            await this.provider.init(config)
        }
    }

    async open() {
        if (this.provider) {
            await this.provider.open()
        }
    }

    async close() {
        if (this.provider) {
            await this.provider.close()
        }
    }

    async identify() {
        if (this.provider) {
            await this.provider.identify()
        }
    }

    async toggle() {
        if (this.provider) {
            await this.provider.toggle()
        }
    }
}

const Chat = new ChatClass()

export {Chat}
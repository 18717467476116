/*
    Manager.js -- Manager global singleton

    import {Auth, Chat, Feedback, Log, Progress, Rest, Routes, Rules, State, Storage, Store}
    import {
        allow, arrayBufferToBase64, blend, can, canUser, clone, createManager, delay, deny, 
        disableAutoComplete, equal, fetch, fixShadow, getVue, getModel, getModels, getRoute, 
        getRoutePath, getRouter, getSelf, getValue, hidden, money, navigate, query, 
        renderDom, unique, vuetifyProps, waitRender
    }
    Usage:
        import {createManager} from 'devcore'
        await createManager(params, config)

    The vue, vuetify and router references are saved in State.ref (non-reactive).
    The app configuration is saved in State.config.
 */

import {initApp} from '@/paks/vu-app/App.js'
import UI from '../ui.json5'
import {Roles} from '@/paks/mgr-data'
import Expression from '@/paks/js-expression'
import blend from '@/paks/js-blend'
import * as Models from '@/paks/mgr-models'

//  Vue components
import VuSign from '@/paks/vu-sign'
import {Dash, DashList, Page, PageList} from '@/paks/vu-dash'
import Forgot from '@/paks/vu-auth/Forgot.vue'
import Login from '@/paks/vu-auth/Login.vue'
import Logout from '@/paks/vu-auth/Logout.vue'
import VuManager from './Manager.vue'
import FeatureEdit from '@/views/Designer/FeatureEdit.vue'
import ThemeEdit from '@/views/Designer/ThemeEdit.vue'
import AccountSettings from '@/views/Account/AccountSettings.vue'
import DeviceClaim from '@/views/Devices/DeviceClaim.vue'
import DeviceList from '@/views/Devices/DeviceList.vue'
import DeviceRelease from '@/views/Devices/DeviceRelease.vue'
import DeviceReleaseAction from '@/views/Devices/DeviceReleaseAction.vue'
import GenericTable from '@/views/Data/GenericTable.vue'
import GenericEdit from '@/views/Data/GenericEdit.vue'
import UserList from '@/views/Users/UserList.vue'
import UserPassword from '@/views/Users/UserPassword.vue'
import UserProfile from '@/views/Users/UserProfile.vue'
import Home from '@/components/Home.vue'
import {AlertEdit, AlertList} from '@/paks/vu-alerts'

class ManagerClass {
    config = null
    params = null

    async create(params, config = {}) {
        this.config = Object.assign({}, UI, Roles, config)
        this.params = Object.assign({}, params)
        this.params.models = {
            ...Models,
            ...params.models,
        }
        this.params.components = {
            ...params.components,
            AlertEdit,
            AlertList,
            AccountSettings,
            Dash,
            DashList,
            DeviceClaim,
            DeviceList,
            DeviceRelease,
            DeviceReleaseAction,
            FeatureEdit,
            Forgot,
            GenericTable,
            GenericEdit,
            Home,
            Login,
            Logout,
            Page,
            PageList,
            ThemeEdit,
            UserPassword,
            UserList,
            UserProfile,
            VuManager,
            VuSign,
        }
        await initApp(this.params, this.config)
        return this
    }
}

const Manager = new ManagerClass()

export async function createManager(params, config) {
    return await Manager.create(params, config)
}

/*
    Blend display sections
 */
export async function blendDisplay(display) {
    if (display.blend) {
        let expression = new Expression({debug: false})
        for (let item of display.blend) {
            let {test, properties} = item
            let enable
            if (test) {
                let ast = expression.parse(test)
                let context = {
                    desktop: navigator.innerWidth >= 640,
                    mobile: navigator.innerWidth < 640,
                    agent: navigator.userAgent,
                    height: window.innerWidth,
                    language: navigator.language,
                    width: window.innerWidth,
                }
                enable = await expression.run(ast, context, 50)
            } else {
                enable = true
            }
            if (enable) {
                blend(display, properties)
                delete display.blend
            }
        }
    }
    return display
}

export {Models}
export * from '@/paks/vu-app'

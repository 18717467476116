/*
    connect.js -- Not distributed, just for local execution
    This file is generated by the Ioto Cloud when using AppEdit.
*/
export default {
    /*
        Prod, Demo using demo@embedthis.com
    */
    demo: {
        api: 'https://4biqo6jhd2.execute-api.us-east-1.amazonaws.com',
        cognito: {
            userPoolId: 'us-east-1_sc39SaHMY',
            clientId: '7uhocngn0pg10skn3c9321mh86'
        },
        host: 'demo.ioto.me'
    },
    /*
        Prod, EcoHouse using demo@embedthis.com
    */
    eco: {
        api: 'https://8opht12ofb.execute-api.ap-southeast-2.amazonaws.com',
        builder: 'https://api.admin.embedthis.com',
        cognito: {
            userPoolId: 'ap-southeast-2_nco5bJaNT',
            clientId: '3l6ao5c3rnir9uj4hrsogrhsb3',
        },
        host: 'ecohouse.ioto.me'
    },
    dev: {
        /*
            QA, Acme using support@embedthis.com
        */
        api: 'https://ygp2t8ckqj.execute-api.ap-southeast-1.amazonaws.com',
        builder: 'https://api.admin-qa.embedthis.com',
        cognito: {
            userPoolId: 'ap-southeast-1_SIDXt5dtg',
            clientId: '7jines74naj5o61c78gg65cbp6',
        },
        host: 'acme.ioto-qa.me'
    },
}
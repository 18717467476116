<script setup>
import {onMounted, reactive, ref, watch} from 'vue'
import {clone, State, can, navigate, titlecase} from '@/paks/vu-app'
import PageEdit from './PageEdit.vue'

const Fields = [
    {name: 'edit', icon: '$edit', width: '5%'},
    {
        name: 'design',
        title: 'Designer',
        icon: (v, rec) => (rec.component == 'Page' && rec.path != '/designer/pages/editor' ? '$dashboard' : ''),
        width: '5%',
    },
    {name: 'show', icon: '$launch', width: '5%'},
    {name: 'component'},
    {name: 'path'},
    {name: 'menu'},
    {name: 'icon', icon: (v, rec) => rec.icon ? rec.icon : ''},
    {name: 'tab', title: 'Tab Group', icon: (v, rec) => Array.isArray(rec.tabs) && rec.tabs.length == 0 ? '$check' : ''},
]

const page = reactive({
    builder: State.config.builder,
    fields: Fields,
    designPageEdit: false,
    showPageEdit: false,
    view: null,
    views: [],
})

const table = ref(null)

watch(
    () => State.app.display,
    async (v) => {
        if (table.value) {
            await table.value.update()
        }
    },
    {deep: true}
)

onMounted(() => {
    page.select = {
        actions: {Add: 0, Edit: 1, Delete: 2},
        multi: true,
        property: 'select',
    }
    page.views = getData(clone(State.app.display.views))
})

function getData(views, result = [], parent) {
    for (let view of views) {
        result.push(view)
        if (parent?.tabs) {
            view.tab = titlecase(view.tab || view.name || view.path)
        }
        if (view.views) {
            getData(view.views, result, view)
        }
    }
    return result
}

async function clicked({action, item, items, column, rec, row}) {
    if (action == 'add') {
        if (!can('admin')) {
            return
        }
        editPage({})
    } else if (action == 'edit' || (action == 'cell' && column.name == 'edit')) {
        if (can('admin')) {
            editPage(item)
        }
    } else if (action == 'cell') {
        if (column.name == 'design') {
            if (item.component == 'Page') {
                navigate('pages/editor', {path: item.path, design: true})
            }
        } else if (column.name == 'show') {
            navigate(item.path)
        }
    }
}

async function editPage(view) {
    page.showPageEdit = view ? true : false
    page.view = view ? Object.assign({}, view) : null
    reload()
}

async function reload() {
    page.views = getData(clone(State.app.display.views))
    if (table.value) {
        await table.value.update()
    }
}
</script>

<template>
    <div class="view page page-list">
        <vu-help url="/doc/ui/view/view-list.html" />
        <vu-table
            :data="page.views"
            :fields="page.fields"
            :select="page.select"
            name="pages"
            nodata=" "
            options="dynamic,refilter,toolbar"
            ref="table"
            sort="path:asc"
            width="100%"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn v-if="can('admin')" color="accent" @click="editPage({})">Add</v-btn>
            </template>
        </vu-table>

        <vu-panel v-model="page.showPageEdit" :keyboard="false" :widths="['550px']" @close="editPage">
            <PageEdit :view="page.view" @input="editPage" />
        </vu-panel>
    </div>
</template>

<style>
.page-list {
    .table-data {
        i {
            color: rgb(var(--v-theme-primary)) !important;
        }
    }
}
</style>
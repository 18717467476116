/*
    main.js -- Manager main
 */

//  Import the manager class, Vuetify helper and Manager CSS
import {blendDisplay, createManager, vuetifyProps} from 'devcore'
import 'devcore/manager.css'
import 'devcore/extras.css'

//  Import the GraphWidget and AWS Cognito authenticator from the managers extras
import {Cognito, GraphWidget} from 'devcore/extras'

//  Import core Vue, VueRouter and Vuetity components
import {createApp} from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import Build from '../build.json5'

/*
    Backend configuration provided by the cloud service
    otherwise, display.js will be an empty {}
 */
import Connect from '../connect.js'
import Display from '../display.js'
import Schema from '../schema.js'
    
//  Import App VueJS components
import Main from './Main.vue'
import Custom from './Custom.vue'
import About from './About.vue'

const Styles = 'styles.css'

//  Import additional MaterialDesign icons
import {mdiFormatListBulletedSquare} from '@mdi/js'
const Icons = {
    events: mdiFormatListBulletedSquare,
}

async function main() {
    /*
        Construct VueJS, Vue Router and Vuetify UI
     */
    let profile = process.env.PROFILE || 'dev'
    let connect = Connect[profile] || Connect
    let display = await blendDisplay(Display)
    let logo = connect.logo || Display.theme?.logo || 'logo.png'

    let vue = createApp(Main)
    let router = createRouter({
        history: createWebHashHistory(),
        routes: [],
    })

    let vuetify = createVuetify(
        vuetifyProps({
            components: components,
            directives: directives,
            icons: Icons,
            themes: display.theme?.modes || display.theme?.themes,
        })
    )
    vue.use(vuetify)

    await createManager({
        components: { About, Custom },              //  App VueJS components
        schema: Schema,                             //  Database schema 
        widgets: {graph: GraphWidget},              //  Dashboard widgets
        cognito: Cognito,                           //  AWS Cognito authenticator
        display: display,                           //  UI Display definition
        router,                                     //  VueJS router
        vue,                                        //  Vue instance
        vuetify,                                    //  Vuetify instance
    }, {
        api: connect.api,                           //  Backend cloud service API
        cognito: connect.cognito,                   //  Cognito config
        build: Build.build,                         //  App build number
        host: connect.host,                         //  Optional override hostname
        logo: logo,                                 //  App logo
        profile: profile,                           //  Vite execution profile (prod | dev)
        name: display.name,                         //  App name
        title: display.title,                       //  App display title
        version: display.version,                   //  App version (x.y.z)
    })

    /*
        Load the custom CSS uploaded via the Builder
        Do this rather than via index.html link because of a Vite bug (6737)
     */
    let link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = `${window.origin}/${Styles}`
    document.head.appendChild(link)

    /*
        Start VueJS and authenticate the user
     */
    vue.use(router)
    vue.mount('#main')
    router.push({path: '/login'})
}

main()

<script setup>
import {getCurrentInstance, onBeforeUnmount, onMounted, reactive, ref} from 'vue'
import {Feedback, State, getModels, navigate, waitRender} from '@/paks/vu-app'
import AudioWidget from './AudioWidget.vue'
import ButtonWidget from './ButtonWidget.vue'
import EventWidget from './EventWidget.vue'
import GaugeWidget from './GaugeWidget.vue'
import FormWidget from './FormWidget.vue'
import ImageWidget from './ImageWidget.vue'
import InputWidget from './InputWidget.vue'
import LedWidget from './LedWidget.vue'
import ListWidget from './ListWidget.vue'
import MetricTableWidget from './MetricTableWidget.vue'
import ProgressWidget from './ProgressWidget.vue'
import SignWidget from './SignWidget.vue'
import ShapeWidget from './ShapeWidget.vue'
import TableWidget from './TableWidget.vue'
import TabsWidget from './TabsWidget.vue'
import ToolbarWidget from './ToolbarWidget.vue'
import ValueWidget from './ValueWidget.vue'
import Expression from '@/paks/js-expression'
import Json5 from 'json5'

let {proxy: self} = getCurrentInstance()

const GridMin = 20
const GridSize = 20

const props = defineProps({
    dashboard: Object,
    design: Boolean,
    value: Number | Object,
    widget: Object,
})

defineExpose({update})

const emit = defineEmits(['add', 'click', 'edit', 'remove', 'resize'])

const page = reactive({
    canEditWidgets: null,
    dark: null,
    footer: null,
    framed: null,
    header: null,
    mirror: null,
    pos: null,
    resizing: false,
    title: null,
    widgets: null,
})

//  Component refs
const resizeHandle = ref(null)
const widgetHeaders = ref(null)
const cardRef = ref(null)
const widgetRef = ref(null)
const header = ref(null)
const footer = ref(null)
const title = ref(null)

let listeners = []

onMounted(async () => {
    await prepWidget()
    window.addEventListener('resize', prepWidget)
})

onBeforeUnmount(() => {
    removeListeners()
})

async function prepWidget() {
    let widget = props.widget
    if (!widget) return

    page.footer = widget.footer
    page.header = widget.header
    page.title = widget.title
}

async function update(params) {
    let widget = props.widget
    page.framed = widget.framed != null ? widget.framed : props.dashboard.framed
    page.canEditWidgets = State.config.features?.dash?.edit && props.design
    page.dark = State.app.dark ? 'dark' : ''
    if (widgetRef.value) {
        await setWidgetStyles()

        //  Apply style overrides for header, footer and title content
        let el = title.value?.$el
        if (el) {
            page.title = el.style['--w-content'] || widget.title
        }
        el = header.value
        if (el) {
            page.header = el.style['--w-content'] || widget.header
        }
        el = footer.value
        if (el) {
            page.footer = el.style['--w-content'] || widget.footer
        }

        if (widgetRef.value?.update) {
            await widgetRef.value?.update(params)
        }
    }
    if (resizeHandle.value) {
        addListener(resizeHandle.value.$el, 'mousedown', startResize)
        addListener(resizeHandle.value.$el, 'touchstart', startResize)
    }
}

function bindWidget(type) {
    for (let [key, component] of Object.entries(State.ref.widgets)) {
        if (key == type) {
            return component
        }
    }
    console.error(`Cannot find widget type ${type}`)
    return null
}

async function setWidgetStyles() {
    let el = widgetRef.value?.$el
    let widget = props.widget
    if (props.dashboard.widgetCss) {
        await setStyles(widget, el, props.dashboard.widgetCss)
    }
    let background = props.widget.css.filter((c) => c.name == 'background')
    if (background.length) {
        let el = cardRef.value?.$el
        await setStyles(widget, el, background)
    }
    await setStyles(widget, el, props.widget.css)
    await waitRender()
}

async function setStyles(widget, el, css) {
    if (!el) return
    /*
        [expression:][component.][name] = value
     */
    let expression = new Expression({debug: false})
    let context = {
        agent: navigator.userAgent,
        dark: State.app.dark,
        design: props.design,
        desktop: !State.app.mobile,
        framed: props.dashboard.framed,
        full: props.dashboard.full,
        height: window.innerWidth,
        language: navigator.language,
        light: !State.app.dark,
        live: props.dashboard.live,
        mobile: State.app.mobile,
        value: widget.value,
        width: window.innerWidth,
        db: async (table, field, props) => {
            try {
                let item = await Generic.get(table, props)
                if (item && item[field] != null) {
                    return item[field]
                }
            } catch (err) {}
            return null
        }
    }
    for (let item of css) {
        let {name, value} = item

        let matches = name.match(/((.*):)*(.*)+/)
        try {
            let [, , condition, path] = matches || []
            let enabled
            if (condition) {
                let ast = expression.parse(condition)
                enabled = await expression.run(ast, context, 50)
            } else {
                // enabled = (widget.text || widget.value) ? true : false
                enabled = true
            }
            patchStyle(widget, el, path, enabled, value)
        } catch (err) {
            console.log('Bad CSS', err.message, item)
        }
    }
}

/*
    Patch the "path" element where path is a dot separated class list followed by property name. 
    Eg.  component.footer.background
    Value is the CSS property value, enabled is the condition result or widget.value if no condition
    The value can be "trueValue:falseValue" and the appropriate value is picked depending on "enabled".
    If the path ends with "class" then we must remove the class if disabled.
 */
function patchStyle(widget, el, path, enabled, value) {
    let parts = path.split('.')
    let prop = parts.at(-1).trim()

    for (let component of parts.slice(0, -1)) {
        let children
        if (
            (component == 'header' || component == 'footer' || component == 'title') &&
            component == parts[0]
        ) {
            children = widgetHeaders.value.children
        } else {
            children = el.children
        }
        el = Array.from(children).find((e) => Array.from(e.classList).find((c) => c == component))
        if (!el) break
    }
    if (!el) {
        return
    }
    value = value.toString().trim()
    let options = value.split(':')
    if (options.length > 1) {
        value = enabled ? options[0] : options[1]
    }
    if (prop == 'class') {
        //  Remove both true:false classes
        for (let c of options) {
            el.classList.remove(c)
        }
        el.classList.add(value)
        return
    }
    if (options.length == 1 && !enabled) {
        return
    }
    el.style[prop] = value

    if (prop == 'color' && parts.length == 1) {
        //  Apply top-level prop to widget headers too
        if (title.value) {
            title.value.$el.style[prop] = value
        }
        if (widgetHeaders.value) {
            widgetHeaders.value.style[prop] = value
        }
    }
}

function startResize(e) {
    e.stopPropagation()
    let element = self.$el

    addListener(document, 'mousemove', resize)
    addListener(document, 'mouseup', finishResize)
    addListener(document, 'touchmove', resize)
    addListener(document, 'touchend', finishResize)

    let mirror = (page.mirror = element.cloneNode(true))
    mirror.id = props.widget.id + '-mirror'

    let pos = (page.pos = getPos(e, element))
    mirror.style.width = align(pos.width) + 'px'
    mirror.style.height = align(pos.height) + 'px'

    mirror.style.top = element.offsetTop + 'px'
    mirror.style.left = element.offsetLeft + 'px'
    mirror.className += ' resizing-widget'
    mirror.style.zIndex = 1000
    mirror.style.opacity = 0.5

    element.parentElement.appendChild(mirror)
    notifyResizing('start-resize', pos)
    page.resizing = true
}

function resize(e) {
    if (!page.resizing) return
    let mirror = page.mirror
    e.stopPropagation()
    let pos = (page.pos = getPos(e, mirror))
    let width = align(pos.width)
    let height = align(pos.height)
    mirror.style.width = width + 'px'
    mirror.style.height = height + 'px'
    notifyResizing('resize', pos)
    if (resizeHandle.value) {
        resizeHandle.value.$el.style.cursor = 'nwse-resize'
    }
}

async function finishResize(e) {
    if (page.resizing) {
        let mirror = page.mirror
        e.stopPropagation()
        let pos = getPos(e, mirror)
        pos.width = align(pos.width)
        pos.height = align(pos.height)
        notifyResizing('finish-resize', pos)
        if (resizeHandle.value) {
            resizeHandle.value.$el.style.cursor = ''
        }
        removeListeners(['mousemove', 'mouseup', 'touchmove', 'touchend'])
        await waitRender()
        self.$el.parentElement.removeChild(mirror)
        page.resizing = null
    }
}

function getPos(e, widget) {
    let bounds = widget.getBoundingClientRect()
    let {left, top} = bounds
    let width, height
    if (e.touches) {
        if (e.touches.length) {
            width = e.touches[0].clientX - bounds.left
            height = e.touches[0].clientY - bounds.top
        } else {
            width = page.pos.width
            height = page.pos.height
        }
    } else {
        width = e.clientX - bounds.left
        height = e.clientY - bounds.top
    }
    return {height, width, left, top, bounds}
}

async function editWidget() {
    emit('edit', props.widget)
}

async function removeWidget() {
    emit('remove', props.widget)
}

function notifyResizing(cmd, pos) {
    emit('resize', {
        cmd: cmd,
        id: props.widget.id,
        height: pos.height,
        width: pos.width,
    })
}

function addListener(base, event, fn) {
    listeners.push({base, event, fn})
    base.addEventListener(event, fn)
}

function removeListeners(events) {
    for (let listener of listeners) {
        if (!events || events.indexOf(listener.event) >= 0) {
            listener.base.removeEventListener(listener.event, listener.fn)
        }
    }
    listeners = []
}

function align(v) {
    if (props.dashboard.layout == 'grid') {
        return Math.max(Math.round(v / GridSize) * GridSize, GridMin)
    }
    return v
}

function rightClick(e) {
    e.preventDefault()
    State.app.setNeed('editWidget', props.widget)
    return false
}

async function widgetClicked(e) {
    let widget = props.widget
    if (props.design) {
        return
    }
    if (widget.type == 'input' || widget.type == 'form' || widget.type == 'toolbar' || widget.type == 'table' || widget.type == 'metric') {
        return
    }
    if (widget.moved && widget.moved > Date.now() - 1000) {
        return
    }
    if (widgetRef.click) {
        widgetRef.click(e)
    }
    await run(widget)
}

async function run(widget, fields = {}) {
    let action = widget.action
    if (!action) {
        return
    }
    let {type, target, conditions} = action
    let {Action, Dashboard, Generic} = getModels()
    if (target) {
        if (type == 'dashboard') {
            await Dashboard.setByName(target)
            State.app.setNeed('board', 'reload')
        } else if (type == 'link') {
            navigate(target)
        } else if (type == 'action' && conditions && conditions.length) {
            widget.loading = true
            await waitRender()
            let expression = new Expression({debug: false})
            let context = {
                agent: navigator.userAgent,
                dark: State.app.dark,
                design: props.design,
                desktop: !State.app.mobile,
                framed: props.dashboard.framed,
                full: props.dashboard.full,
                height: window.innerWidth,
                language: navigator.language,
                live: props.dashboard.live,
                mobile: State.app.mobile,
                value: widget.value,
                width: window.innerWidth,
                db: async (table, field, props) => {
                    try {
                        let item = await Generic.get(table, props)
                        if (item && item[field] != null) {
                            return item[field]
                        }
                    } catch (err) {}
                    return null
                }
            }
            for (let cond of conditions) {
                if (cond.expression) {
                    //  FUTURE - really want functions for db access?
                    let ast = expression.parse(cond.expression)
                    if (!(await expression.run(ast, context, 50))) {
                        continue
                    }
                }
                if (cond.params) {
                    let params = cond.params
                    try {
                        if (params[0] == '{') params = Json5.parse(params) /*}*/
                        params = Object.assign({}, params, State.app.context, params, fields)
                    } catch (err) {
                        console.log(`Cannot parse JSON`, params)
                    }
                    try {
                        await Action.invoke({name: target, params})
                        State.app.setNeed('board', 'refreshData')
                    } catch (err) {
                        Feedback.error(err.message)
                    }
                    break
                }
            }
            widget.loading = false
        }
    }
}

async function widgetInput(form) {
    if (!form) return
    let fields = {}
    for (let widget of props.dashboard.widgets) {
        if (widget.form == form && widget.type != 'form') {
            let name = widget.field || widget.label?.toLowerCase()
            if (name) {
                fields[name] = widget.value
            }
        }
    }
    await run(form, fields)
}
</script>

<template>
    <div class="widget" :id="widget.id">
        <v-card
            v-show="!widget.hide"
            ref="cardRef"
            :hover="false"
            :elevation="0"
            class="widget-card"
            :class="`widget-${widget.type} ${widget.zoomed || ''} 
                ${page.framed ? 'framed' : 'flat'}
                ${props.design ? 'design' : 'fixed'}
            `"
            @click.alt="rightClick"
            @contextmenu.prevent.stop="rightClick"
            @click="widgetClicked">
            <v-card-title ref="title" class="widget-title" v-if="page.title && page.framed">
                {{ page.title }}
            </v-card-title>
            <v-card-text>
                <div
                    v-if="
                        widget.value == null &&
                        widget.type != 'input' &&
                        widget.text == null &&
                        (widget.metric || widget.model)
                    "
                    class="nodata"
                    align="center"
                    justify="center">
                    Waiting for data...
                </div>
                <AudioWidget
                    v-else-if="widget.type == 'audio'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <ButtonWidget
                    v-else-if="widget.type == 'button'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <EventWidget
                    v-else-if="widget.type == 'event'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <FormWidget
                    v-else-if="widget.type == 'form'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef"
                    @input="widgetInput" />
                <GaugeWidget
                    v-else-if="widget.type == 'gauge'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <ImageWidget
                    v-else-if="widget.type == 'image'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <InputWidget
                    v-else-if="widget.type == 'input'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <LedWidget
                    v-else-if="widget.type == 'led'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <ListWidget
                    v-else-if="widget.type == 'list'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <MetricTableWidget
                    v-else-if="widget.type == 'metric'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <ProgressWidget
                    v-else-if="widget.type == 'progress'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <SignWidget
                    v-else-if="widget.type == 'sign'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <ShapeWidget
                    v-else-if="widget.type == 'shape'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <TableWidget
                    v-else-if="widget.type == 'table'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <TabsWidget
                    v-else-if="widget.type == 'tabs'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <ValueWidget
                    v-else-if="widget.type == 'numeric' || widget.type == 'label' || widget.type == 'text'"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <ToolbarWidget
                    v-else-if="widget.type == 'toolbar'"
                    :board="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
                <component
                    v-else
                    v-if="bindWidget(widget.type)"
                    :is="bindWidget(widget.type)"
                    :dashboard="dashboard"
                    :widget="widget"
                    :state="State"
                    ref="widgetRef" />
            </v-card-text>

            <div
                class="widget-headers"
                ref="widgetHeaders"
                v-if="
                    widget.type != 'tabs' &&
                    widget.type != 'input' &&
                    widget.type != 'form' &&
                    widget.type != 'metric' &&
                    widget.type != 'sign' &&
                    widget.type != 'toolbar'
                ">
                <div ref="header" class="header" :class="`header-${widget.type}`">
                    {{ page.header }}
                </div>
                <div ref="footer" class="footer" :class="`footer-${widget.type}`">
                    {{ page.footer }}
                </div>
            </div>
            <div v-if="widget.shadow === true" class="shadow">
                <v-icon icon="$close" size="large" />
            </div>
        </v-card>

        <div
            v-if="page.canEditWidgets && !widget.fixed"
            class="manage-widget"
            :class="widget.zoomed">
            <v-icon
                v-if="!widget.zoomed"
                :class="`manage-icon pr-1 ${page.dark}`"
                icon="$edit"
                @mousedown.stop="editWidget"
                @touchstart.stop="editWidget" />
        </div>
        <v-icon
            icon="$resize"
            v-if="page.canEditWidgets && !widget.zoomed && widget.type != '__toolbar'"
            class="resize-handle"
            ref="resizeHandle" />
    </div>
</template>

<style lang="scss" scoped>
.widget {
    .widget-card {
        height: 100%;
        width: 100%;
        border: solid 1px rgb(var(--v-theme-border));
        border-radius: 0;
        background: rgb(var(--v-theme-background-lighten-1));
        display: flex;
        flex-direction: column;
        overflow: hidden;

        box-shadow: none !important;
        transition: none !important;

        .v-card-title {
            text-align: center;
            line-height: 1.2;
        }
        .v-card-text {
            container-type: size;
            flex-grow: 1;
            height: 100%;
            width: 100%;
            padding: 0;
            user-select: none;
            background: inherit;
            pointer-events: auto;
        }
        .nodata {
            position: absolute;
            top: 45%;
            width: 100%;
            text-align: center;
            font-size: 1.2rem;
            color: rgb(var(--v-theme-secondary-lighten-2));
        }
    }
    .widget-button:not(.framed),
    .widget-text:not(.framed),
    .widget-image:not(.framed),
    .widget-input:not(.framed),
    .widget-form:not(.framed),
    .widget-shape:not(.framed) {
        background: transparent;
    }
    .widget-card.flat {
        box-shadow: none !important;
        border-radius: 0 !important;
        border: none !important;
    }
    .widget-card.zoomed {
        border-radius: 0 !important;
        border: none !important;
    }
    .widget-card.design {
        cursor: move;
    }
    .widget-toolbar {
        background: inherit;
        z-index: 10;
    }
    .manage-widget {
        position: absolute;
        text-align: right;
        top: 0px;
        right: 0px;
        height: 26px;
        width: 44px;
        z-index: 12;
        cursor: pointer;
        opacity: 0;
        @media (max-width: 640px) {
            opacity: 0.5;
        }
        .manage-icon {
            font-size: 1.8rem;
            color: rgb(var(--v-theme-primary-lighten-1));
            opacity: 0.75;
        }
        .manage-icon.dark {
            color: white;
        }
    }
    .manage-widget:hover {
        opacity: 1;
    }
    .move-widget:hover + .manage-widget {
        opacity: 1;
    }
    .resize-handle {
        position: absolute !important;
        color: rgb(var(--v-theme-border));
        bottom: 0;
        right: 0;
        opacity: 0;
        z-index: 12;
    }
    .resize-handle:hover {
        cursor: nwse-resize;
        opacity: 1;
    }
    .manage-widget.zoomed {
        display: block;
        height: 24px !important;
        width: 24px !important;
        right: 6px !important;
    }
    .zoomed {
        border: none !important;
        border-radius: 0 !important;
    }
    .widget-headers {
        height: 100%;
        width: 100%;
        container-type: size;
        position: absolute;
        text-align: center;
        .header {
            position: relative;
            top: 10cqh;
            text-align: center;
            width: 100%;
            font-size: max(16px, min(min(6cqh, 6cqw), 68px));
            font-weight: normal;
            color: inherit;
        }
        .header-gauge {
            top: 3cqh;
        }
        .header-graph {
            top: 0cqh;
        }
        .footer {
            position: absolute;
            bottom: 4cqh;
            text-align: center;
            width: 100%;
            opacity: 0.75;
            font-size: max(12px, min(min(4cqh, 4cqw), 48px));
            font-weight: normal;
            color: inherit;
        }
        .footer-graph {
            top: 92cqh;
        }
        pointer-events: none;
    }
    .shadow {
        container-type: size;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #888888;
        opacity: 50%;
        pointer-events: fill;
        cursor: move;
        z-index: 2;
        .v-icon {
            position: relative;
            top: 50cqh;
            left: 50cqw;
            transform: translate(-50%, -50%);
            display: block;
            width: 80cqw;
            height: 80cqh;
        }
    }
}
.resizing-widget {
    position: absolute;
    font-family: Roboto, sans-serif;
    .v-card-actions {
        .v-btn {
            margin-right: 8px !important;
            background-color: rgb(var(--v-theme-accent)) !important;
            border-color: rgb(var(--v-theme-accent)) !important;
        }
    }
}
</style>

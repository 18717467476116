<script setup>
import {onMounted} from 'vue'
import {Auth, navigate} from '@/paks/vu-app'

onMounted(async () => {
    await Auth.logout({redirect: true})
    navigate('/login')
})
</script>

<template>
    <div />
</template>

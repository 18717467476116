<script setup>
import {onMounted, reactive, ref} from 'vue'
import {State, clone, getModels, unique, navigate} from '@/paks/vu-app'
import {provision} from '@/paks/vu-dash'

const props = defineProps({})

const emit = defineEmits(['input'])

const page = reactive({
    features: [],
    groups: [],
    manager: {},
})

const Features = [
    {group: 'Auth', path: 'auth.forgot', type: 'checkbox', tip: 'Forgot password recovery'},
    {group: 'Auth', path: 'auth.login', type: 'checkbox', tip: 'Login required to use app'},

    {group: 'Cloud', path: 'cloud.enable', type: 'checkbox', tip: 'Enable cloud-based management'},

    {group: 'Dashboard', path: 'dash.edit', type: 'checkbox', tip: 'Enable dashboard editing'},
    {group: 'Dashboard', path: 'dash.database', type: 'checkbox', tip: 'Enable dashboards database access'},
    {group: 'Dashboard', path: 'dash.metrics', type: 'checkbox', tip: 'Enable dashboard metrics'},
    {group: 'Dashboard', path: 'dash.multiple', type: 'checkbox', tip: 'Support multiple dashboards'},
    {group: 'Dashboard', path: 'dash.responsive', type: 'checkbox', tip: 'Support responsive dashboards'},

    // {group: 'Demo', path: 'demo.enable', type: 'checkbox', tip: ''},

    {group: 'Navigation', path: 'nav.alerts', type: 'checkbox', tip: 'Display alert notifications in navbar'},
    {group: 'Navigation', path: 'nav.cookies', type: 'checkbox', tip: 'Display navigation cookies in tabbed pages'},
    {group: 'Navigation', path: 'nav.dark', type: 'checkbox', tip: 'Display dark theme option in navbar'},
    {group: 'Navigation', path: 'nav.doc', type: 'checkbox', tip: 'Display documentation link in navbar'},
    {group: 'Navigation', path: 'nav.help', type: 'checkbox', tip: 'Support help panel'},
    {group: 'Navigation', path: 'nav.profile', type: 'checkbox', tip: 'Display user account profile link'},
    {group: 'Navigation', path: 'nav.settings', type: 'checkbox', tip: 'Display user account settings link'},
    {group: 'Navigation', path: 'nav.sidebar', type: 'checkbox', tip: 'Add app sidebar navigation'},
    // {group: 'Navigation', path: 'nav.signposts', type: 'checkbox', tip: ''},
    {group: 'Navigation', path: 'nav.navbar', type: 'checkbox', tip: 'Add top level app navigation bar'},

    {group: 'Widget', path: 'widget.actions', type: 'checkbox', tip: 'Enable widget actions'},
    {group: 'Widget', path: 'widget.multiCloud', type: 'checkbox', tip: 'Widgets should support multiple clouds'},
]

const {Manager} = getModels()

//  Component refs
const self = ref(null)
const confirm = ref(null)

onMounted(async () => {
    page.features = mapFeatures(Features)
    page.manager = await Manager.get()
    page.groups = unique(Features.map((f) => f.group))
})

function mapFeatures(features) {
    features = clone(features)
    for (let item of features) {
        let value = State.app.display.features
        for (let prop of item.path.split('.')) {
            value = value[prop]
            if (!value) break
        }
        item.value = value
    }
    return features
}

async function save() {
    let display = clone(State.app.display)
    let features = display.features
    for (let item of page.features) {
        let feature = features
        let leaf
        let parts = item.path.split('.')
        for (let prop of parts) {
            leaf = feature
            feature = feature[prop]
        }
        if (leaf) {
            leaf[parts.at(-1)] = item.value
        }
    }
    display.features = features
    await provision({ui: display, update: true})
    emit('input')
}

function cancel() {
    navigate('/designer/pages')
}
</script>

<template>
    <vu-form
        class="feature-edit page-form ma-5"
        cols="6"
        help="/doc/ui/view/feature-edit.html"
        ref="self"
        :data="page"
        :save="save">
        <div v-for="group of page.groups" class="vrow mb-5">
            <label class="groups">{{ group }}</label>
            <div v-for="item of page.features.filter(f => f.group == group)" class="vrow">
                <vu-input
                    v-model="item.value"
                    :type="item.type"
                    cols="6"
                    :label="`${item.tip} (${item.path})`"
                    hide-details />
            </div>
        </div>

        <div class="actions">
            <v-btn size="small" color="accent" type="submit" :loading="page.saving">Save</v-btn>
            <v-btn size="small" color="none" @click="cancel">Cancel</v-btn>
        </div>

        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
.feature-edit {
    font-size: 0.9rem;
    .groups {
        font-size: 1.2em;
    }
    .v-select-list {
        padding: 0;
        border: solid 1px rgb(var(--v-theme-border-lighten-1));
        border-radius: 0;
    }
    h3 {
        font-weight: normal;
        color: rgb(var(--v-theme-text));
    }
    .layouts {
        margin: 10px 0 20px 0;
    }
    .actions {
        margin: 20px 0 20px 0;
    }
    .live-data {
        //  When grid, helps alignment
        flex-grow: 30;
    }
}
</style>

<script setup>
import { reactive } from 'vue'
import { clone, getRoute} from 'devcore'

const page = reactive({
    view: null,
})

let route = getRoute()
let view = page.view = clone(route.meta.view)
</script>

<template>
    <div class="custom-style">
        <h3>Custom Component</h3>
        <p>Color: {{ page.view.properties.color }}</p>
    </div>
</template>

<style lang="scss" scoped>
.custom-style {
    padding: 40px;
}
</style>
<script setup>
import {getCurrentInstance, nextTick, onBeforeMount, onUnmounted, reactive, ref} from 'vue'
import Dates from '@/paks/js-dates'
import {Generic} from '@/paks/mgr-models'
import {Feedback, Progress, Routes, State} from '@/paks/vu-app'
import {can, clone, equal, getModel, navigate, titlecase} from '@/paks/vu-app'

const {proxy: self} = getCurrentInstance()

const PageSize = 20

const page = reactive({
    buttons: [],
    click: {},
    component: null,
    editable: false,
    fields: null,
    focussed: null,
    item: {},
    items: [],
    invoke: {},
    loading: false,
    model: null,
    name: null,
    panel: null,
    panels: {},
    pivot: false,
    pristine: [],
    saving: false,
    select: null,
    title: null,
    updates: [],
    view: null,
})

const panelRefs = {}

const StoreFields = [
    {name: 'deviceId', type: 'string', disabled: true},
    {name: 'key', type: 'string'},
    {name: 'value', type: 'object'},
    {name: 'type', type: 'string'},
    {name: 'expires', type: 'date', desktop: true},
    {name: 'updated', type: 'date', desktop: true},
]
const CommandFields = [
    {name: 'accountId', type: 'string', disabled: true},
    {name: 'deviceId', type: 'string', disabled: true},
    {name: 'description', type: 'string'},
    {name: 'command', type: 'string'},
    {name: 'args', type: 'object'},
    // {name: 'expires', type: 'date'},
    {name: 'updated', type: 'date', desktop: true},
    {name: 'expires', type: 'date', format: (v) => (!v || v.getTime() == 0) ? 'never' : v, desktop: true},
]
const table = ref(null)
const confirm = ref(null)

onBeforeMount(async () => {
    let route = self.$route
    let view = (page.view = clone(route.meta.view))
    page.name = view.name

    if (view.panels) {
        for (let panel of view.panels) {
            if (can(panel.role)) {
                page.panels[panel.name] = panel
            }
            if (panel.button) {
                page.buttons.push(panel.button)
            }
            panelRefs[panel.name] = panel.componentRef
        }
    }
    let table = view.table
    if (table) {
        page.editable = table.editable
        page.fields = table.fields
        page.title = Routes.template(table.title, State.app.context)
        page.subtitle = table.subtitle
        page.schema = State.app.schema
        page.model = Routes.template(table.model, State.app.context)

        if (page.fields) {
            /*
                For launch fields, setup a click
            */
            for (let field of Object.values(page.fields)) {
                if (field.launch) {
                    page.click[field.name] = field.launch
                }
                if (field.name == '*') field.enable = false
            }
            if (page.fields.filter((f) => f.enable !== false).length == 0) {
                page.fields = null
            }
        }
        if (!page.fields) {
            if (page.model == 'Command') {
                page.fields = CommandFields
            } else if (page.model == 'Store') {
                page.fields = StoreFields
            } else {
                let model = getModel(page.model)
                if (model) {
                    let fields = []
                    for (let [name, field] of Object.entries(model)) {
                        if (field.hidden || name == '_type' || name == 'pk' || name == 'sk') continue
                        fields.push({name})
                    }
                    page.fields = fields
                }
            }
        }
        if (table.actions) {
            /*
                Define the table select actions
             */
            let actions = {}
            for (let [key, def] of Object.entries(table.actions)) {
                actions[titlecase(key)] = def.count
                if (def.invoke) {
                    page.invoke[key] = def.invoke
                }
                if (def.launch) {
                    page.click[key] = page.panels[def.launch]
                }
            }
            page.select = {actions, multi: true, property: '_select_'}
        }
    }
    if (page.fields && !page.fields.find(f => f.name == 'select')) {
        if (page.click['*']) {
            page.fields = clone(page.fields)
            page.fields.unshift({name: 'select', icon: '$click', width: '5%'})
        } else if (page.click.edit || page.panels.edit) {
            page.fields = clone(page.fields)
            page.fields.unshift({name: 'edit', icon: '$edit', width: '5%'})
        }
    }
    State.app.unsaved = (to) => {
        return hasUnsaved(to)
    }
    window.addEventListener('beforeunload', checkChanged)
})

onUnmounted(() => {
    window.removeEventListener('beforeunload', checkChanged)
})

function hasUnsaved(to) {
    if (page.updates.length) {
        nextTick(async () => {
            if (await confirm.value.ask(`<p>Do you want to navigate away and lose your your changes?</p>`)) {
                State.app.unsaved = null
                navigate(to.path)
            }
        })
        return true
    }
    return false
}

//  Navigate away check
function checkChanged(event) {
    if (page.updates.length == 0) return
    event.preventDefault()
    event.returnValue = ''
}

async function getData(args) {
    let schema = page.schema
    if (page.model == '_Tables_') {
        let grid = []
        for (let [name, model] of Object.entries(schema.models)) {
            if (schema.process[name] && schema.process[name].show === false) {
                continue
            }
            if (name == '_Schema' || name == '_Migration') continue
            let def = {name}
            let process = State.app.schema.process[name]
            if (process) {
                def.enable = process.enable == 'both' ? 'cloud, device' : process.enable
                def.sync = process.sync
                def.notify = process.notify
            }
            grid.push(def)
        }
        return grid
    }
    let body = {}
    if (page.name != 'devices' && page.model != 'Device') {
        let deviceId = State.app.context.deviceId
        if (deviceId && deviceId.indexOf(':') < 0 && deviceId != 'select') {
            if (page.model == 'Device') {
                body.id = State.app.context.deviceId
            } else {
                body.deviceId = State.app.context.deviceId
            }
        }
    }
    page.pivot = (schema.process[page.model]?.pivot) ? true : false
    let data = await Generic.find(page.model, body, args)
    for (let row of data) {
        delete row._select_
    }
    page.pristine = clone(data)
    return data
}

async function deleteItems(items) {
    if (!(await confirm.value.ask(`Do you want to delete the selected items?`))) {
        return
    }
    for (let item of items) {
        await Generic.remove(item._type, item)
    }
    await table.value.update()
    Feedback.info('Item Deleted')
}

async function save() {
    Progress.start('repeat')
    page.saving = true
    for (let item of page.updates) {
        delete item._select_
        await Generic.update(page.model, item)
    }
    page.updates = []
    page.saving = false
    Progress.stop()
}

async function clicked({action, column, item, items}) {
    if (action == 'delete') {
        await deleteItems(items)

    } else if (action == 'cell' && column) {
        if (action == 'cell' && column?.name == 'edit') {
            let panel = page.click.edit || page.panels.edit
            if (panel) {
                page.item = item || {}
                controlPanel(panel, page.item)
                return
            }
        }
        let to = page.click[column.name] || page.click['*']
        if (to) {
            if (page.panels[to]) {
                controlPanel(page.panels[to], item)
            } else {
                //  TODO generalize
                if (page.model == 'Device' || page.model == 'Store') {
                    State.app.setContext('deviceId', item.id)
                } else if (page.model == '_Tables_') {
                    State.app.setContext('table', item.name)
                }
                to = Routes.template(to, item, State.app.context)
                navigate(to)
            }
        }
    } else if (page.invoke[action]) {
        if (page.view.table.actions[action].confirm) {
            let title = page.view.table.actions[action].title || action
            //  FUTURE - need custom prompts
            if (!(await confirm.value.ask(`Do you want to ${title} the selected items?`))) {
                return
            }
        }
        page.component = await Routes.getComponent(page.invoke[action])
        page.item = item || {}
        page.items = items || []
    } else {
        let panel = page.click[action] || page.panels[action]
        if (panel) {
            page.item = item || {}
            controlPanel(panel, page.item)
        }
    }
}

async function controlPanel(panel, item) {
    if (panel) {
        //  Pop up
        page.panels[panel.name].show = item ? true : false
        page.panel = panel
    } else if (page.panel && page.panels[page.panel.name]) {
        //  Pop down
        page.panels[page.panel.name].show = false
        await table.value.update()
    }
    page.item = item || {}
}

function buttonClick(btn) {
    let panel = page.view.panels.find((panel) => panel.button == btn)
    if (panel) {
        controlPanel(panel, {})
    }
}

async function complete() {
    page.component = null
    await table.value.update()
}

function editable(item, vfield) {
    let schema = page.schema
    if (!schema) {
        return true
    }
    //  Keys and type properties are not editable
    let name = vfield.attribute
    let type = schema.params.typeField
    if (name == type || name == 'pk' || name == 'sk') {
        return false
    }
    if (page.model == 'Store' && name == 'key') {
        return false
    }
    if (page.model == 'Device') {
        return false
    }
    let model = schema.models[page.model]
    if (!model) {
        return false
    }
    //  Check field is defined and does not have a value template
    let field = model[name]
    return field && !field.value && !field.readonly && !field.hidden ? true : false
}

function formatValue(item, vfield, value) {
    let schema = page.schema
    let fieldName = vfield.name
    vfield.attribute = fieldName

    if (page.editable && editable(item, vfield) && can('admin')) {
        vfield.editable = true
        vfield.css += ' editable'
    } else {
        vfield.editable = false
    }
    let field = page.fields.find(f => f.name == fieldName)
    let format = field.format 

    if (value == null) {
        value = ''
    } else if (value instanceof Date) {
        if (isNaN(value.getTime())) {
            value = null
        } else {
            // value = options.iso ? value.toISOString() : value.getTime()
            value = Dates.format(value, format || State.config.theme.formats.time)
        }
        return value
    } else if (typeof value == 'number') {
        value = value.toLocaleString()
    } else if (Array.isArray(value)) {
        value = JSON.stringify(value)
    } else if (typeof value == 'object') {
        value = JSON.stringify(value, null, 4)
    }
    if (format) {
        value = Routes.template(value.toString(), {n: value}, State.app.context)
    }
    return value.toString()
}

async function editableEnterKey(event, props) {
    let {field, item, row} = props
    let value = event.target.innerHTML
    await blur(item, field, value, row)
    event.target.blur()
}

async function editableFocus(event, props) {
    let {field} = props
    page.focussed = field.id
}

async function editableBlur(event, props) {
    let {field, item, row} = props
    let value = event.target.innerHTML
    await blur(item, field, value, row)
}

async function blur(item, vfield, value, row = -1) {
    let model = page.schema.models[page.model]
    if (!model) return
    let field = model[vfield.attribute]
    if (field) {
        let type = field.type
        if (type == 'object' && vfield.name == 'value' && item.type) {
            type = item.type
        }
        if (type) {
            item[vfield.name] = valueFromBrowser(value, type)
        }
    }
    delete item._select_
    if (!equal(item, page.pristine[row])) {
        saveItem(item, row)
        page.focussed = null
    }
}

function saveItem(item, row) {
    page.updates.splice(row, 0, clone(item))
    page.pristine[row] = clone(item)
}

/*
    Transform a value from web form input to the requisite type as defined by the schema.
*/
function valueFromBrowser(value, type) {
    try {
        if (value == null) {
            return null
        }
        let trimmed = value.toString().trim()
        if (type == 'string') {
            //  Special case JSON in string to get into canonical JSON form
            if (trimmed[0] == '{' /*}*/) {
                try {
                    value = JSON.stringify(JSON.parse(trimmed))
                } catch (err) {}
            } else {
                value = trimmed
            }
        } else if (type == 'number') {
            value = +trimmed
        } else if (type == 'boolean') {
            value = value === true || trimmed === 'true' || trimmed === 0 ? true : false
        } else if (type == 'date') {
            if (parseInt(value) == value) {
                value = new Date(+value)
            } else {
                if (!(value instanceof Date)) {
                    value = new Date(trimmed)
                }
                if (isNaN(value.getTime())) {
                    value = null
                }
            }
        } else if (type == 'array') {
            if (!Array.isArray(value)) {
                if (trimmed.length) {
                    if (trimmed[0] == '[') {
                        value = JSON.parse(trimmed)
                    } else {
                        value = trimmed.split(',').map((v) => v.trim())
                    }
                } else {
                    value = []
                }
            }
        } else if (type == 'object') {
            if (typeof value != 'object' && trimmed[0] == '{' /*}*/) {
                value = JSON.parse(trimmed)
            } else {
                value = trimmed
            }
        }
    } catch (err) {
        // Ignore error and keep original value. JSON.parse may fail.
    }
    return value
}

</script>

<template>
    <div class="page generic-list">
        <vu-table
            class="generic-table"
            options="dynamic,filter,toolbar"
            ref="table"
            width="100%"
            :callbacks="{format: formatValue}"
            :data="getData"
            :fields="page.pivot ? null : page.fields"
            :name="page.name"
            :nodata="page.loading ? 'Loading...' : 'No Items'"
            :pageSize="PageSize"
            :pivot="page.pivot ? 'value' : null"
            :select="page.select"
            :subtitle="page.subtitle"
            :sort="page.sort"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn v-show="page.updates.length" color="accent" @click="save" :loading="page.saving">
                    Save
                </v-btn>
                <v-btn
                    v-for="(btn, index) in page.buttons"
                    class="mr-2"
                    :color="index == 0 ? 'accent' : ''"
                    :variant="index > 0 ? 'outlined' : 'elevated'"
                    :key="btn"
                    @click="buttonClick(btn)">
                    {{ btn }}
                </v-btn>
            </template>
            <template v-slot:cell="props">
                <div
                    v-if="props.field.editable"
                    :contenteditable="props.field.editable"
                    spellcheck="false"
                    class="cell-value"
                    :id="props.field.id"
                    @blur="editableBlur($event, props)"
                    @focus="editableFocus($event, props)"
                    @keydown.enter.prevent="editableEnterKey($event, props)">
                    {{ props.field.value }}
                </div>
                <div v-else class="cell-value" contenteditable="false">{{ props.field.value }}</div>
            </template>
        </vu-table>

        <vu-panel
            v-for="panel in page.panels"
            v-model="panel.show"
            :key="panel.name"
            :widths="[panel.width || '700px']"
            @close="controlPanel()">
            <component
                v-bind:is="panelRefs[panel.name]"
                :item="page.item"
                :model="page.model"
                :panel="panel"
                :title="panel.title"
                @input="controlPanel()" />
        </vu-panel>

        <component
            v-if="page.component"
            v-bind:is="page.component"
            :item="page.item"
            :items="page.items"
            :model="page.model"
            :title="page.title"
            @input="complete" />

        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.generic-list {
    .table-title {
        padding-top: 6px;
    }
    td.editable {
        padding: 4px 7px 4px 7px;
        background: rgba(220, 255, 220, 0.4) !important;
        margin: 2px;
        cursor: text;
        &:focus {
            outline: 2px solid rgba(0, 0, 255, 0.5) !important;
            background: white !important;
            width: calc(100% - 4px);
        }
    }
    .table-cell {
        padding: 0 !important;
        .cell-attribute {
            padding: 3px 6px 3px 6px;
            background: rgba(50, 50, 50, 0.1);
            color: black;
        }
        .cell-value {
            margin: 1px;
            padding: 3px 6px 3px 6px;
        }
    }
}
</style>
